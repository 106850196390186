import authService from './auth.service';
import backendService from './backend.service';

class EmojiService {
  getAllExistingEmojis() {
    return fetch(backendService.API_URL + '/api/emoji/all').then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Error while loading all emojis!');
    });
  }

  getEmojiInfo(emoji: string) {
    return fetch(backendService.API_URL + '/api/emoji/info/' + emoji, {
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Error while getting emoji infos!');
    });
  }

  buyEmoji(emoji: string) {
    return fetch(backendService.API_URL + '/api/emoji/buy/' + emoji, {
      method: 'POST',
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    });
  }
}
export default new EmojiService();
