import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AdminStats } from '../../models/adminStats';
import backendService from '../../services/backend.service';
import { toast } from 'react-toastify';

export default function AdminMainPage() {
  const [stats, setStats] = useState<AdminStats>();

  useEffect(() => {
    backendService
      .getAdminStats()
      .then((stats) => setStats(stats))
      .catch((err) => toast.error(err));
  }, []);

  return (
    <div className="admin-warn-background">
      <div className="container mx-auto py-10 px-4 min-h-screen">
        <h1 className="text-4xl mb-4">
          <b>ADMIN</b> Overview
        </h1>
        <div className="mt-10 flex flex-row gap-3">
          <Link
            to="/admin/games"
            className={'p-5 shadow-lg rounded text-center px-10 w-full md:w-fit bg-white text-black'}
          >
            <div>{stats ? stats.games : '...'}</div>
            <span className="text-2xl font-bold">Games</span>
          </Link>
          <Link
            to="/admin/users"
            className={'p-5 shadow-lg rounded text-center px-10 w-full md:w-fit bg-white text-black'}
          >
            <div>{stats ? stats.users : '...'}</div>
            <span className="text-2xl font-bold">Users</span>
          </Link>
          <Link
            to="/admin/betaaliens"
            className={'p-5 shadow-lg rounded text-center px-10 w-full md:w-fit bg-white text-black'}
          >
            <div>{stats ? stats.betaAliens : '...'}</div>
            <span className="text-2xl font-bold">Beta Aliens</span>
          </Link>
          <Link
            to="/admin/spaceships"
            className={'p-5 shadow-lg rounded text-center px-10 w-full md:w-fit bg-white text-black'}
          >
            <div>{stats ? stats.betaAliens : '...'}</div>
            <span className="text-2xl font-bold">Spaceships</span>
          </Link>
        </div>

        <div className="mt-10 flex flex-row gap-3">
          <Link to="/admin/previewSpaceship" className="button--primary">
            Preview Spaceship Creation
          </Link>
        </div>
      </div>
    </div>
  );
}
