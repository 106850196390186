import { toast } from 'react-toastify';
import { WithdrawRequest, WithdrawSubmit } from '../models/withdrawRequest';
import authService from './auth.service';
import backendService from './backend.service';

class TokenService {
  getTokenBalance() {
    return fetch(backendService.API_URL + '/api/tokens/balance', {
      headers: new Headers({ authorization: authService.getAuthToken() }),
    });
  }

  async withdrawTokens(request: WithdrawRequest) {
    let txId = undefined;
    try {
      const walletName = localStorage.getItem('cardanoWalletConnector');
      const cardanoWallet = await (window as any).cardano[walletName].enable();
      const withdrawResponse = await fetch(backendService.API_URL + '/api/tokens/withdraw', {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          authorization: authService.getAuthToken(),
          'Content-Type': 'application/json',
        },
      });
      if (!withdrawResponse.ok) {
        const responseJson = await withdrawResponse.json();
        toast.error(responseJson.error);
        return;
      }

      const withdrawJson = await withdrawResponse.json();
      txId = withdrawJson.id;
      const witness = await cardanoWallet.signTx(withdrawJson.tx, true);

      const submitResponse = await fetch(backendService.API_URL + '/api/tokens/submit', {
        method: 'POST',
        body: JSON.stringify({
          id: withdrawJson.id,
          userWitness: witness,
        } as WithdrawSubmit),
        headers: {
          authorization: authService.getAuthToken(),
          'Content-Type': 'application/json',
        },
      });
      if (submitResponse.ok) {
        toast.success(
          'Successfully withdraw tokens, please wait a few minutes until the transaction is processed by the chain.'
        );
      } else {
        throw Error('Error while withdraw tokens :(');
      }
    } catch (err) {
      toast.error('Error while withdraw tokens: ' + JSON.stringify(err));
      // quit tx, just in case
      await this.cancelTokenWithdraw(txId);
    }
  }

  cancelTokenWithdraw(txId: string) {
    return fetch(backendService.API_URL + '/api/tokens/tx/' + txId, {
      method: 'DELETE',
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    });
  }
}

export default new TokenService();
