import { faMeteor, faStar, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  text: string;
}

export default function EmptyViewComponent(props: Props) {
  return (
    <div className="w-full h-52">
      <FontAwesomeIcon icon={faStar} className="absolute left-1/2 ml-6 mt-2" />
      <FontAwesomeIcon icon={faStar} className="absolute left-1/3 ml-6" />
      <FontAwesomeIcon icon={faStarOfLife} className="absolute left-1/4 mt-12" />
      <FontAwesomeIcon icon={faStar} className="absolute left-1/3 mt-36" />
      <span className="text-center block mt-10">{props.text}</span>
      <FontAwesomeIcon icon={faMeteor} className="absolute left-1/2 mt-10 -ml-14" size="6x" />

      <FontAwesomeIcon icon={faStarOfLife} className="absolute right-1/3 mt-3" />
      <FontAwesomeIcon icon={faStar} className="absolute right-1/4 mt-36 -mr-12" />
      <FontAwesomeIcon icon={faStarOfLife} className="absolute right-1/4 -mt-16" />
    </div>
  );
}
