import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmptyViewComponent from '../components/empty-view';
import SpaceshipItem from '../components/spaceship-item';
import { Spaceship } from '../models/spaceship';
import { ServerStatus } from '../models/status';
import authService from '../services/auth.service';
import backendService from '../services/backend.service';
import spaceshipService from '../services/spaceship.service';

export default function HangarPage() {
  const [spaceships, setSpaceships] = useState<Spaceship[]>();
  const [status, setStatus] = useState<ServerStatus>();

  useEffect(() => {
    spaceshipService
      .getSpaceshipsOfCurrentUser()
      .then((response) => {
        if (response.ok) {
          response.json().then((r) => setSpaceships(r));
        } else {
          toast.error('Cannot load your Spaceships, please try again later...');
        }
      })
      .catch((err) => toast.error('Cannot load Spaceships. Error: ' + err));
    backendService.getStatus().then((status) => setStatus(status));
  }, []);

  if (!authService.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container mx-auto py-10 px-4 min-h-screen">
      <div className="flex flex-row mb-8">
        <h1 className="text-4xl flex-grow">Your hangar</h1>
        {status && status.gameActive && (
          <div className="mt-2">
            <Link to="/createSpaceship" className="button--primary orange-gradient">
              <FontAwesomeIcon icon={faRocket} /> Create a new Spaceship!
            </Link>
          </div>
        )}
      </div>

      {spaceships ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-16 gap-4">
          {spaceships.map((spaceship) => {
            return <SpaceshipItem spaceship={spaceship} key={spaceship.id} />;
          })}
          {spaceships.length === 0 ? <EmptyViewComponent text="Your hangar is empty..." /> : null}
        </div>
      ) : null}

      {status && !status.gameActive && (
        <Link to="/beta-alien" className="button--primary">
          <FontAwesomeIcon icon={faRocket} /> Get your Beta Alien NFT!
        </Link>
      )}
    </div>
  );
}
