import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Action from '../../models/game.Actions';

interface Props {
  actionSelf: Action;
  actionOpponent: Action;
  round: number;
}

export default function ActionBalloonComponent(props: Props) {
  const [showBattleAnimation, setShowBattleAnimation] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setShowBattleAnimation(true);
    setTimeout(() => {
      setShowBattleAnimation(false);
    }, 3000);
  }, [props.round]);

  return (
    <div className="w-full">
      <div className="mx-auto text-center">
        <div className="action--bubble action--bubble--self">
          <p>{t('game.actions.' + props.actionSelf)}</p>
        </div>
        <div className="action--bubble action--bubble--opponent">
          <p>{t('game.actions.' + props.actionOpponent)}</p>
        </div>
      </div>
      {showBattleAnimation ? (
        <div className="grid grid-cols-1 grid-rows-1 h-full">
          <div className="action--canvas--self w-full">
            <div className={props.actionSelf}></div>
          </div>
          <div className="action--canvas--opponent w-full">
            <div className={props.actionOpponent}></div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
