import { SupportedCollection } from '../models/supportedCollection';

interface Props {
  collection: SupportedCollection;
}

export default function SupportedCollectionComponent(props: Props) {
  const collection = props.collection;

  return (
    <div className="my-4 text-black flex">
      <div className="w-2/5 rounded-l shadow-2xl z-10 bg-white">
        <img src={collection.logoUrl} className="object-cover rounded h-full" alt="Logo of Collection" />
      </div>
      <div className="bg-white w-3/5 rounded-r">
        <div className="p-4">
          <h3 className="text-2xl font-bold">{collection.name}</h3>
          <p>{collection.description}</p>
          <p className="truncate my-6" title={collection.id}>
            Collection ID: {collection.id}
          </p>
          <a
            href={collection.linkUrl}
            target="_blank"
            rel="noreferrer"
            className="bg-blue-700 py-2 px-6 rounded mt-4 text-white"
          >
            Project Website
          </a>
        </div>
      </div>
    </div>
  );
}
