import { faInfoCircle, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Chain } from '../models/chain';
import { Spaceship } from '../models/spaceship';
import authService from '../services/auth.service';

interface Props {
  spaceship: Spaceship;
}

export default function SpaceshipItem(props: Props) {
  const spaceship = props.spaceship;

  return (
    <div className="md:max-w-xs hover:scale-105 smooth--transition">
      <img src={spaceship.img} alt="Spaceship" />
      <div className="w-full bg-white text-black p-3">
        <h3 className="text-xl truncate mb-2">{spaceship.name}</h3>
        {spaceship.type === Chain.cardano ? (
          <div className="truncate">
            <img src="/img/chains/cardano-black.svg" className="h-6 inline-block" alt="Cardano Chain" title="Cardano" />
            <span className="ml-2">{spaceship.id}</span>
          </div>
        ) : null}
        {spaceship.currentOwnerId === authService.getUserId() ? (
          <div className="grid grid-cols-2 gap-3 mt-4 text-center">
            <Link
              to={'/spaceship/' + spaceship.id}
              className="flex-grow shadow-lg py-4 px-2 rounded bg-gray-400 text-white hover:bg-gray-300 smooth--transition"
            >
              <FontAwesomeIcon icon={faInfoCircle} /> View Spaceship
            </Link>
            {spaceship.isActive ? (
              <Link
                to={'/game?ship=' + spaceship.id}
                className="flex-grow shadow-lg py-4 px-2 rounded bg-gray-700 text-white hover:bg-gray-600 smooth--transition"
              >
                <FontAwesomeIcon icon={faRocket} /> Start Game!
              </Link>
            ) : (
              <span className="flex-grow py-4 px-2">Not ready yet</span>
            )}
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-3 mt-4 text-center">
            <Link
              to={'/spaceship/' + spaceship.id}
              className="flex-grow shadow-lg py-4 px-2 rounded bg-gray-400 text-white hover:bg-gray-300 smooth--transition"
            >
              <FontAwesomeIcon icon={faInfoCircle} /> View Spaceship
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
