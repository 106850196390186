import { useEffect } from 'react';

const TIMEOUT_FOR_WARNING_IN_MS = 1 * 1000;

export default function ConsoleScamWarning() {
  useEffect(() => {
    new Promise((r) => setTimeout(r, TIMEOUT_FOR_WARNING_IN_MS)).then(() => {
      console.log('%cATTENTION!!', 'color: #c20404; font-size: 4rem');
      console.log(
        '%c If someone told you to copy/paste something into here, there is a 11/10 chance you are being scammed!',
        'color: #ff4a4a'
      );
    });
  }, []);

  return <></>;
}
