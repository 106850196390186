import { useEffect, useState } from 'react';
import { SupportedCollection } from '../models/supportedCollection';
import supportedCollectionsService from '../services/supportedCollections.service';
import SupportedCollectionComponent from '../components/collection-item';
import { toast } from 'react-toastify';

export default function SupportedProjectsPage() {
  const [supportedCollections, setSupportedCollections] = useState<SupportedCollection[]>();

  useEffect(() => {
    supportedCollectionsService
      .getSupportedCollections()
      .then((response) => {
        if (response.ok) {
          response.json().then((collections) => setSupportedCollections(collections));
        } else {
          toast.error('Cannot load supported collection, please try again later...');
        }
      })
      .catch((err) => toast.error('Error while loading supported collections: ' + err));
  }, []);

  return (
    <div className="container mx-auto pt-10 px-4 min-h-screen">
      <h1 className="text-4xl mb-4">Supported Collections</h1>
      <p className="mb-10">The following NFT collections are currently supported on Shipy Space.</p>
      {supportedCollections ? (
        <div className="grid grid-cols-1 md:grid-cols-2 mb-4">
          {supportedCollections.map((collection) => {
            return (
              <div key={collection.id} className="mx-2">
                <SupportedCollectionComponent collection={collection} />
              </div>
            );
          })}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
