import { faArrowAltCircleRight, faBan, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TokenWithdrawal, TransactionStatus } from '../models/withdrawRequest';
import tokenService from '../services/token.service';

interface Props {
  transaction: TokenWithdrawal;
  reloadBalance: () => void;
}

export default function TokenWithdrawTransaction(props: Props) {
  const transaction = props.transaction;
  const { t } = useTranslation();

  const getStatusSpecificClasses = () => {
    switch (transaction.status) {
      case TransactionStatus.CONFIRMED:
        return 'bg-green-600';
      case TransactionStatus.SUBMITTED:
        return 'bg-emerald-600';
      case TransactionStatus.FAILED:
        return 'bg-red-600 line-through';
      case TransactionStatus.PENDING_USER_SIGNATURE:
        return 'bg-orange-600';
      default:
        return 'bg-gray-500';
    }
  };

  const getTxIcon = () => {
    switch (transaction.status) {
      case TransactionStatus.FAILED:
        return faBan;
      case TransactionStatus.PENDING_USER_SIGNATURE:
        return faExclamationTriangle;
      default:
        return faArrowAltCircleRight;
    }
  };

  const quitTransaction = (id: string) => {
    tokenService.cancelTokenWithdraw(id).then((response) => {
      if (response.ok) {
        toast.info('Successfully removed pending transaction');
        props.reloadBalance();
      } else {
        toast.error('Cannot remove pending transaction...');
      }
    });
  };

  return (
    <a
      className={'flex flex-col md:flex-row py-2 my-2 rounded shadow ' + getStatusSpecificClasses()}
      href={transaction.txUrl}
      target="_blank"
      rel="noreferrer"
    >
      <span className="grid content-center mx-4 my-2 md:my-0">
        <FontAwesomeIcon icon={getTxIcon()} size="2x" className="mx-auto" />
      </span>
      <div className="flex-grow truncate mx-4 md:mx-0">
        <div className="text-lg">NBD Withdrawal {format(new Date(transaction.timestamp), 'yyyy-MM-dd HH:mm')}</div>

        {transaction.txId ? (
          <div className="text-gray-300 truncate">Transaction ID: {transaction.txId}</div>
        ) : (
          transaction.status === TransactionStatus.PENDING_USER_SIGNATURE && (
            <button
              className="bg-white py-2 px-6 rounded text-black hover:bg-gray-100 shadow smooth--transition"
              onClick={() => quitTransaction(transaction.id)}
            >
              Quit Transaction
            </button>
          )
        )}
      </div>
      <span className="grid content-center mx-4 mt-4 md:mt-0">{t('withdrawal.status.' + transaction.status)}</span>
      <span className="grid content-center mx-4 mb-4 md:mb-0">{transaction.amount} NBD</span>
    </a>
  );
}
