import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Game } from '../../models/game.model';
import runningGamesService from '../../services/runningGames.service';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

export default function SingleGameAdminView() {
  const { id } = useParams();
  const [game, setGame] = useState<Game>();

  useEffect(() => {
    runningGamesService
      .getSingleGame(id)
      .then((game) => setGame(game))
      .catch((err) => toast.error(err));
  }, [id]);

  return (
    <div className="admin-warn-background">
      <div className="container mx-auto py-10 px-4 min-h-screen">
        <h1 className="text-4xl mb-4">
          <b>
            <Link to="/admin">ADMIN</Link>
          </b>{' '}
          / <Link to="/admin/games">Games</Link> / {id}
        </h1>

        {game ? (
          <div>
            <table className="mb-4 text-left">
              <tr>
                <th className="pr-4">Start:</th>
                <td>{game.startDate?.toLocaleString()}</td>
              </tr>
              <tr>
                <th className="pr-4">End:</th>
                <td>{game.endDate?.toLocaleString()}</td>
              </tr>
              <tr>
                <th className="pr-4">Gained NBD Tokens:</th>
                <td>{game.gainedTokens}</td>
              </tr>
              <tr>
                <th className="pr-4">Gained XPs:</th>
                <td>{game.gainedExperiencePoints}</td>
              </tr>
            </table>
            <div className="grid grid-cols-2">
              <div>
                <p className="text-xl font-bold">
                  Player 1 {game.winner === game.player1.userId ? <FontAwesomeIcon icon={faTrophy} /> : null}
                </p>
                <p>UserID: {game.player1.userId}</p>
                <p>Spaceship: {game.player1.spaceshipId}</p>
              </div>
              <div>
                <p className="text-xl font-bold">
                  Player 2 {game.winner === game.player2.userId ? <FontAwesomeIcon icon={faTrophy} /> : null}
                </p>
                <p>UserID: {game.player2.userId}</p>
                <p>Spaceship: {game.player2.spaceshipId}</p>
              </div>
            </div>

            <p className="text-xl font-bold mt-10">Rounds</p>
            <div>
              {game.rounds
                .filter((r) => r.roundNumber !== 0)
                .map((round) => {
                  return (
                    <div>
                      <p className="font-bold">Round {round.roundNumber}</p>
                      <div className="grid grid-cols-2 mb-4">
                        <div>
                          Player 1 selected {round.actionPlayer1} (Healthdelta: {round.deltaHealthPlayer1})
                        </div>
                        <div>
                          Player 2 selected {round.actionPlayer2} (Healthdelta: {round.deltaHealthPlayer2})
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}
