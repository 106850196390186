import { faBug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface State {
  hasError: boolean;
  errorInfo: string | null;
}

interface Props {
  children: React.ReactNode;
}

export default class GeneralErrorBoundary extends React.Component<{ children }, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo) {
    console.error('Uncaught error while rendering! Error: ', error, errorInfo);
    this.setState({ hasError: true, errorInfo: error + ' ' + errorInfo.componentStack });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="py-10 min-h-screen px-4 error-gradient">
          <div className="container mx-auto">
            <h1 className="text-4xl font-bold mb-10">
              <FontAwesomeIcon icon={faBug} /> Something went wrong <FontAwesomeIcon icon={faBug} />
            </h1>
            <p className="text-xl mb-5">
              Not your fault (probably). Please get in touch with the devs to get this fixed! If they ask, this is the
              error info:
            </p>
            <pre className="whitespace-pre-wrap">{this.state.errorInfo}</pre>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
