import { User } from '../models/user';
import authService from './auth.service';
import backendService from './backend.service';

class UserService {
  getLeaderboard(offset: number = 0) {
    return fetch(backendService.API_URL + '/api/leaderboard/users?offset=' + offset);
  }

  public async getAllUsers(): Promise<User[]> {
    const response = await fetch(backendService.API_URL + '/api/admin/users', {
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Cannot load all users!');
    });
    return response;
  }

  public async getUserAdmin(id: string): Promise<User> {
    const response = await fetch(backendService.API_URL + '/api/admin/users/' + id, {
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Cannot load user ' + id);
    });
    return response;
  }

  public async getProfile(id: string): Promise<User> {
    return await fetch(backendService.API_URL + '/api/profile/' + id).then((reponse) => {
      if (reponse.ok) {
        return reponse.json();
      }
      return Promise.reject('Error while fetching profile ' + id);
    });
  }
}

export default new UserService();
