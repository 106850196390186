import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import spaceshipService from '../services/spaceship.service';
import { Spaceship, SpaceshipState } from '../models/spaceship';
import { Chain } from '../models/chain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseye,
  faFistRaised,
  faRocket,
  faShieldAlt,
  faStar,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import rocketAnimation from '../animations/rocket-animation.json';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import pilotService from '../services/pilot.service';
import { Tooltip } from 'react-tooltip';
import Lottie from 'lottie-web';
import authService from '../services/auth.service';

export default function SpaceshipDetailViewPage() {
  const { id } = useParams();
  const [spaceship, setSpaceship] = useState<Spaceship>();
  const { t } = useTranslation();
  const spaceshipAssemblingAnimationRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    spaceshipService
      .getSpaceshipById(id)
      .then((response) => {
        if (response.ok) {
          response.json().then((ship) => setSpaceship(ship));
        } else {
          toast.error('Cannot load spaceship, please try again later...');
        }
      })
      .catch((err) => toast.error('Error while loading spaceship: ' + err));
  }, [id]);

  useEffect(() => {
    if (spaceshipAssemblingAnimationRef) {
      Lottie.loadAnimation({
        container: spaceshipAssemblingAnimationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: rocketAnimation,
      });
    }
  }, [spaceship]);

  const retryPayment = () => {
    pilotService
      .cardanoPilotPayment(spaceship)
      .then(() => {
        toast.success('Payment completed, spaceship is ready to use!');
        spaceshipService
          .getSpaceshipById(id)
          .then((response) => {
            if (response.ok) {
              response.json().then((ship) => setSpaceship(ship));
            } else {
              toast.error('Cannot load spaceship, please try again later...');
            }
          })
          .catch((err) => toast.error('Error while loading spaceship: ' + err));
      })
      .catch((err) => {
        toast.error('Cannot complete spaceship creation! Please try again');
      });
  };

  const deleteSpaceship = () => {
    spaceshipService
      .deleteSpaceship(spaceship.id)
      .then((response) => {
        if (response.ok) {
          toast.success('Spaceship deleted!');
          navigate('/game/hangar');
        } else {
          toast.error('Cannot delete spaceship :/');
        }
      })
      .catch((err) => {
        toast.error('Error while deleting spaceship: ' + err);
      });
  };

  return (
    <div className="container mx-auto py-10 min-h-screen px-4">
      {spaceship !== undefined ? (
        <div className="pt-10 flex flex-wrap flex-col lg:flex-row">
          <img src={spaceship.img} alt="Spaceship" className="w-full lg:w-1/3" />
          <div className="lg:w-2/3 md:pl-5 pt-4">
            <div className="flex mb-6">
              {spaceship.type === Chain.cardano ? (
                <img src="/img/chains/cardano-white.svg" className="h-10 mr-2" alt="Cardano Chain" title="Cardano" />
              ) : null}
              <h1 className="text-4xl inline">{spaceship.name}</h1>
            </div>
            <div className="flex">
              {spaceship.isActive && spaceship.currentOwnerId === authService.getUserId() ? (
                <Link
                  to={'/game?ship=' + spaceship.id}
                  className="flex-grow shadow-lg py-4 px-4 rounded text-center bg-slate-100 text-black hover:bg-slate-300 smooth--transition"
                >
                  <FontAwesomeIcon icon={faRocket} /> Start Game!
                </Link>
              ) : null}
            </div>
            <div className="grid grid-cols-2 md:grid-cols-5 mt-8 text-center gap-4">
              <div
                className="bg-slate-400 rounded spaceship-attribute-tooltip"
                data-tooltip-html={t('spaceshipattributes.experiencepoints')}
              >
                <div className="my-8">
                  <p>
                    <FontAwesomeIcon icon={faStar} /> XP
                  </p>
                  <p className="text-4xl font-bold">{spaceship.experiencePoints}</p>
                </div>
              </div>
              <div
                className="bg-slate-400 rounded spaceship-attribute-tooltip"
                data-tooltip-html={t('spaceshipattributes.healthpoints')}
              >
                <div className="my-8">
                  <p>
                    <FontAwesomeIcon icon={faShieldAlt} /> Health
                  </p>
                  <p className="text-4xl font-bold">{spaceship.health > 0 ? spaceship.health : '?'}</p>
                </div>
              </div>
              <div
                className="bg-slate-400 rounded spaceship-attribute-tooltip"
                data-tooltip-html={t('spaceshipattributes.attackpoints')}
              >
                <div className="my-8">
                  <p>
                    <FontAwesomeIcon icon={faFistRaised} /> Attack
                  </p>
                  <p className="text-4xl font-bold">{spaceship.attackStrength > 0 ? spaceship.attackStrength : '?'}</p>
                </div>
              </div>
              <div
                className="bg-slate-400 rounded spaceship-attribute-tooltip"
                data-tooltip-html={t('spaceshipattributes.precision')}
              >
                <div className="my-8">
                  <p>
                    <FontAwesomeIcon icon={faBullseye} /> Precision
                  </p>
                  <p className="text-4xl font-bold">{spaceship.precision > 0 ? spaceship.precision + '%' : '?'}</p>
                </div>
              </div>
              <div
                className="bg-slate-400 rounded spaceship-attribute-tooltip"
                data-tooltip-html={t('spaceshipattributes.critical')}
              >
                <div className="my-8">
                  <p>
                    <FontAwesomeIcon icon={faTriangleExclamation} /> Critical
                  </p>
                  <p className="text-4xl font-bold">
                    {spaceship.precision > 0 ? 100 - spaceship.precision + -'%' : '?'}
                  </p>
                </div>
              </div>
              <Tooltip className="max-w-xs" anchorSelect=".spaceship-attribute-tooltip" place="bottom"></Tooltip>
            </div>
          </div>
          {spaceship.type === 'cardano' && spaceship.isActive ? (
            <div className="my-10 flex">
              <a href={spaceship.cardanoscanUrl} target="_blank" rel="noreferrer" className="button--primary mr-4">
                View on Cardanoscan
              </a>
              <a href={spaceship.poolpmUrl} target="_blank" rel="noreferrer" className="button--primary mr-4">
                View on Pool.pm
              </a>
            </div>
          ) : null}

          {!spaceship.isActive && spaceship.status === SpaceshipState.CREATED ? (
            <div className="mt-10 bg-orange-400 p-4 w-full rounded">
              <span className="text-xl">Spaceship not fully created yet</span>
              <p>Please sign the transaction again to finish the creation of your spaceship!</p>
              <button className="button--primary mt-4" onClick={retryPayment}>
                Re-try payment
              </button>
              <button className="button--primary mt-4 ml-4" onClick={deleteSpaceship}>
                Delete spaceship
              </button>
            </div>
          ) : null}

          {!spaceship.isActive && spaceship.status === SpaceshipState.BLOCKCHAIN_SUBMITTED ? (
            <div className="mt-10 bg-green-600 p-4 w-full text-center rounded">
              <span className="text-xl">Assembling your spaceship in the Blockchain now...</span>
              <p>
                Please be patient! You can play with your ship as soon as the spaceship is validated in the blockchain.
              </p>
              <div ref={spaceshipAssemblingAnimationRef} className="mx-auto max-w-xl" />
            </div>
          ) : null}

          <div className="mt-10 w-full">
            <h2 className="text-4xl mb-4">Actions</h2>
            <div className="gap-4 mb-10 grid grid-cols-1 lg:grid-cols-2">
              {spaceship.actions.map((action) => {
                return (
                  <div className="flex flex-row bg-slate-300 p-4 gap-4 text-slate-900">
                    <div className="basis-1/4">
                      <img src={`/img/actions/${action}.png`} alt={'Action ' + t('game.actions.' + action)} />
                    </div>
                    <div className="basis-3/4">
                      <span className="text-xl">{t(`game.actions.${action}`)}</span>
                      <p>{t(`game.actionhelp.${action}`)}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="w-full mt-10">
            <h2 className="text-4xl mb-2">Pilot NFT</h2>
            <div className="flex flex-col lg:flex-row gap-4 mt-4 mb-12">
              <img src={spaceship.pilot.imgUrl} alt="Pilot NFT" className="w-32 h-32" />
              <div>
                <h3 className="text-2xl mb-2">{spaceship.pilot.name}</h3>
                <p>{spaceship.pilot.collectionName}</p>
                <p className="truncate mb-6">
                  Asset ID: <b>{spaceship.pilot.assetId}</b>
                </p>
                <a
                  href={spaceship.pilot.cardanoscanLink}
                  rel="noreferrer"
                  target="_blank"
                  className="button--primary mr-4"
                >
                  View Pilot on Cardanoscan
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
