import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { BetaAlien } from '../../models/betaAlien';
import { CountdownTimer } from './countdown-timer';
import betaAlienService from '../../services/alien.service';
import authService from '../../services/auth.service';
import BetaAlienItem from './beta-alien-item';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import backendService from '../../services/backend.service';
import BetaAlienNotAvailable from './beta-alien-not-available';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';

const START_OF_MINT = new Date('Sat, 26 Nov 2022 10:00:00 UTC');

export default function BetaAlienPage() {
  const [showCountdown, setShowCountdown] = useState(undefined);
  const [showLogin, setShowLogin] = useState(false);
  const [mintAvailable, setMintAvailable] = useState(undefined);
  const [alienIsGenerating, setAlienIsGenerating] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();
  const [personalAlien, setPersonalAlien] = useState<BetaAlien>();
  const [hcaptchaKey, setHcaptchaKey] = useState<string>();

  useEffect(() => {
    if (authService.isLoggedIn()) {
      setShowLogin(false);
      loadHcaptchaKey();
      loadPersonalAlien();
    } else {
      setShowLogin(true);
    }

    if (START_OF_MINT > new Date()) {
      setShowCountdown(true);
    }
  }, []);

  const captcha = useRef(null);

  const disableCountdown = () => {
    setShowCountdown(false);
    setMintAvailable(true);
  };

  const loadPersonalAlien = () => {
    betaAlienService
      .getBetaAlienOfUser()
      .then((alien) => {
        setPersonalAlien(alien);
        if (alien === null) {
          setMintAvailable(true);
        } else {
          setMintAvailable(false);
        }
      })
      .catch((err) => {
        if (err === 'Mint not available anymore' && START_OF_MINT < new Date()) {
          toast.warn(err);
        } else if (START_OF_MINT < new Date()) {
          toast.error('Cannot get your alien from server. Error: ' + err);
        }
        setMintAvailable(false);
      });
  };

  const loadHcaptchaKey = async () => {
    const status = await backendService.getStatus();
    setHcaptchaKey(status.hcaptchaPublicSiteKey);
  };

  const mintAlienNft = async () => {
    captcha.current.resetCaptcha();
    setAlienIsGenerating(true);
    if (!captchaToken) {
      toast.error('Please solve the CAPTCHA first');
      return;
    }
    try {
      const newAlien = await betaAlienService.createBetaAlien(captchaToken);
      setPersonalAlien(newAlien);
      toast.success('Alien successfully created!');
    } catch (err) {
      toast.error('Error while creating alien: ' + JSON.stringify(err));
      console.log(err);
      loadPersonalAlien();
    }
    setAlienIsGenerating(false);
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto py-10 px-4 drop-shadow-xl">
        <h1 className="text-4xl mb-8">Beta Alien NFT Drop</h1>

        {showLogin && (
          <div>
            <div className="mb-6">Please login to start the mint process:</div>
            <Link to="/login" className="button--primary">
              Login
            </Link>
          </div>
        )}

        {showCountdown !== undefined && showCountdown && (
          <div>
            <div className="mb-8">
              The Beta Alien NFT Drop starts at {format(START_OF_MINT, 'MM/dd/yyyy HH:mm')} (
              {START_OF_MINT.toUTCString()}
              ):
            </div>
            <CountdownTimer targetDate={START_OF_MINT} disableCountdown={disableCountdown} />
          </div>
        )}

        {mintAvailable && !personalAlien && (
          <>
            <div className="mb-4 p-4 bg-yellow-400 text-black">Caution: Only single address wallets are supported!</div>
            <div className="mb-4">
              {hcaptchaKey && (
                <HCaptcha
                  ref={captcha}
                  sitekey={hcaptchaKey}
                  onVerify={(token) => setCaptchaToken(token)}
                  onExpire={() => setCaptchaToken(undefined)}
                />
              )}
            </div>
            {!alienIsGenerating ? (
              <button
                onClick={mintAlienNft}
                className="w-full shadow-lg py-4 px-8 rounded bg-gray-50 text-black hover:bg-gray-200 smooth--transition text-center"
              >
                Mint me an Alien now!
              </button>
            ) : (
              <div className="w-full py-4 text-center bg-gray-200 text-black rounded">
                Generating your NFT for you...
              </div>
            )}

            <div className="mt-4">
              Click on the button above to create you personal Beta Alien NFT. The NFT will be tranferred to your
              current wallet automatically after you signed the transaction.
            </div>
          </>
        )}

        {personalAlien && <BetaAlienItem alien={personalAlien} reload={loadPersonalAlien} />}

        {!mintAvailable && mintAvailable !== undefined && !showCountdown && !personalAlien && <BetaAlienNotAvailable />}
      </div>

      <div className="mt-16 bg-gray-200 shadow">
        <div className="container flex flex-wrap mx-auto py-10 px-4 text-black">
          <div className="w-full md:w-3/5 mb-16">
            <h2 className="text-3xl mb-8">About the Beta Aliens</h2>
            <p className="block text-lg mb-16">
              The Beta Aliens are your early access ticket to the world of Shipy Space. We release{' '}
              <b>100 Beta Aliens</b> as Cardano NFTs in this public mint event. As an owner of a Shipy Space Beta Alien,
              you can <b>play the game during the closed beta phase</b>. By doing so you can provide feedback and
              improvement ideas and help shape the future of Shipy Space! The beta phase will most likely start before
              the end of the year. Other people can only play the game after the public release.
            </p>
            <h3 className="text-3xl mb-8">Key Facts</h3>
            <div>
              Number of NFTs: <b>100 pieces</b>
            </div>
            <div className="truncate">
              Policy:{' '}
              <b>
                <a href="https://cardanoscan.io/tokenPolicy/2fb5d856fe09da9930a5bd3015ae3ad64e02803985bd3e9027a2f815">
                  2fb5d856fe09da9930a5bd3015ae3ad64e02803985bd3e9027a2f815
                </a>
              </b>
            </div>
            <div>
              Price per Beta Alien: <b>10 ADA</b>
            </div>
            <div>
              Utility: <b>Early access to ship.space Game</b>
            </div>
          </div>
          <div className="w-full md:w-2/5">
            <img src="/img/aliens/ShipySpaceBetaAlien10.svg" alt="Ship Space Alien 10" className="mx-4" />
          </div>
        </div>
      </div>

      <div>
        <div className="container mx-auto py-10 px-4">
          <div className="flex flex-row md:justify-between flex-wrap gap-y-4">
            <img src="/img/aliens/ShipySpaceBetaAlien05.svg" alt="Shipy.Space NFT 05" className="w-1/2 md:w-1/6" />
            <img src="/img/aliens/ShipySpaceBetaAlien13.svg" alt="Shipy.Space NFT 13" className="w-1/2 md:w-1/6" />
            <img src="/img/aliens/ShipySpaceBetaAlien49.svg" alt="Shipy.Space NFT 49" className="w-1/2 md:w-1/6" />
            <img src="/img/aliens/ShipySpaceBetaAlien53.svg" alt="Shipy.Space NFT 53" className="w-1/2 md:w-1/6" />
          </div>
        </div>

        <div className="container mx-auto py-10 px-4">
          <p className="text-center text-xl mb-4">Don't forget to follow us on Twitter and join our Discord</p>
          <ul className="flex justify-center flex-1 text-white text-6xl">
            <li>
              <a
                href="https://twitter.com/ShipySpace"
                target="_blank"
                rel="noreferrer"
                className="mx-2 flex-1 items-center hover:text-gray-300 transition duration-300 ease-in-out"
              >
                <FontAwesomeIcon icon={faTwitter} title="Twitter Link" />
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/7uGsJdaNAn"
                target="_blank"
                rel="noreferrer"
                className="mx-2 flex-1 items-center hover:text-gray-300 transition duration-300 ease-in-out"
              >
                <FontAwesomeIcon icon={faDiscord} title="Discord Link" />
              </a>
            </li>
          </ul>
          <p className="text-center text-xl my-4">to get the latest news on the game development</p>
        </div>
      </div>
    </div>
  );
}
