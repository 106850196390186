import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BetaAlien } from '../../models/betaAlien';
import alienService from '../../services/alien.service';
import { Link } from 'react-router-dom';

export default function BetaAliensAdminPage() {
  const [betaAliens, setBetaAliens] = useState<BetaAlien[]>([]);

  useEffect(() => {
    loadBetaAliens();
  }, []);

  const loadBetaAliens = () => {
    alienService
      .getAllBetaAliens()
      .then((aliens) => setBetaAliens(aliens))
      .catch((err) => toast.error('Cannot fetch beta aliens. Error: ', err));
  };

  return (
    <div className="admin-warn-background">
      <div className="container mx-auto py-10 px-4 min-h-screen">
        <h1 className="text-4xl mb-4">
          <b>
            <Link to="/admin">ADMIN</Link>
          </b>{' '}
          / Beta Aliens
        </h1>

        <div className="flex flex-col">
          {betaAliens
            ? betaAliens.map((alien) => {
                return (
                  <div key={alien.id} className="flex flex-row bg-orange-400 rounded py-3 px-2 mb-4">
                    <img src={alien.imgUrl} alt="Beta Alien Preview" className="w-32 h-32 mr-4" />
                    <div className="flex flex-col gap-2">
                      <div className="text-xl font-bold">{alien.name}</div>
                      <div>{alien.status}</div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}
