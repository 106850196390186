import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useLoading } from './AsyncLoading';

interface ReactEngineProps {
  engine: Engine;
  pck: string;
  executable?: string;
  width?: number;
  height?: number;
  params?: any;
  resize?: boolean;
}

function toFailure(err) {
  var msg = err.message || err;
  console.error(msg);
  return { msg, mode: 'notice', initialized: true };
}

const ReactCanvas: FunctionComponent<ReactEngineProps> = ({ engine, pck, executable }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [instance, setInstance] = useState<any>();
  const [currentWidth, setWidth] = useState(0);
  const [loadingState, changeLoadingState] = useLoading();

  useEffect(() => {
    if (engine.isWebGLAvailable()) {
      changeLoadingState({ mode: 'indeterminate' });
      setWidth(canvasRef.current.offsetWidth);
      setInstance(new engine());
    } else {
      changeLoadingState(toFailure('WebGL not available'));
    }
  }, [engine]);

  useEffect(() => {
    if (instance) {
      instance
        .startGame({
          mainPack: pck,
          executable: executable,
          canvas: canvasRef,
          canvasResizePolicy: 0,
          unloadAfterInit: true,
        })
        .then(() => {
          changeLoadingState({ mode: 'hidden', initialized: true });
        })
        .catch((err) => changeLoadingState(toFailure(err)));
    }
    return () => {
      if (instance) {
        instance.requestQuit();
      }
    };
  }, [instance]);

  return (
    <canvas
      ref={canvasRef}
      id="canvas"
      width={currentWidth}
      height={(currentWidth / 16) * 9}
      style={{ display: loadingState.initializing ? 'hidden' : 'block', width: '100%', height: '100%' }}
    >
      HTML5 canvas appears to be unsupported in the current browser.
      <br />
      Please try updating or use a different browser.
    </canvas>
  );
};

export default ReactCanvas;
