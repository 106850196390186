import Action from '../../models/game.Actions';
import { PlayerTransferState } from '../../models/game.model';

interface Props {
  player: PlayerTransferState;
}

export function EffectShieldComponent(props: Props) {
  const shieldActive =
    props.player.activeDefenseEffects?.filter(
      (effect) => effect.id === Action.shieldDefense && effect.remainingRounds > 0
    ).length > 0;

  return (
    <div className="mx-4 break-all text-center">
      <div
        className={
          'h-full bg-blue-600 w-3 border-blue-900 shadow transition-opacity duration-300 ease-in-out ' +
          (shieldActive ? 'opacity-100' : 'opacity-0')
        }
      ></div>
    </div>
  );
}
