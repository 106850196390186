import { Chain } from './chain';
import { Game } from './game.model';
import { Spaceship } from './spaceship';

export interface User {
  id: string;
  index?: number;
  name?: string;
  wallets: {
    type: Chain;
    address: string;
    walletUrl?: string;
  }[];
  experiencePoints: number;
  tokenAmount: number;
  emojis: string[];
  badges: Badges[];
  profilePicture?: string;
  spaceships?: Spaceship[];
  adaHandles?: string[];
  games?: Game[];
}

export enum Badges {
  BETA_TESTER = 'betatester',
  ADMIN = 'admin',
}
