import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { User } from '../models/user';
import userService from '../services/user.service';
import authService from '../services/auth.service';
import { Link } from 'react-router-dom';

const SPECIAL_BG_COLORS = {
  1: 'bg-yellow-500',
  2: 'bg-gray-400',
  3: 'bg-amber-700',
};

export default function LeaderboardPage() {
  const [users, setUsers] = useState<User[]>();

  useEffect(() => {
    userService.getLeaderboard().then((response) => {
      if (response.ok) {
        response.json().then((users) => {
          setUsers(
            users.map((user, index) => {
              user.index = index + 1;
              return user;
            })
          );
        });
      } else {
        toast.error('Error while getting leaderboard :(');
      }
    });
  }, []);

  const getColorForIndex = (index: number): string => {
    if (SPECIAL_BG_COLORS[index]) {
      return SPECIAL_BG_COLORS[index];
    }
    return 'bg-gray-700';
  };

  return (
    <div className="container mx-auto pt-10 min-h-screen px-4 mb-10">
      <h1 className="text-4xl mb-8">Leaderboard</h1>
      {users ? (
        <ul>
          {users.map((user) => (
            <li key={user.id}>
              <Link to={'/profile/' + user.id} className={'flex rounded mb-2 ' + getColorForIndex(user.index)}>
                <div className="px-2 py-4 min-w-[4rem] text-center">{user.index}</div>
                <div className="py-1 w-12 min-w-[3rem]">
                  {user.profilePicture ? (
                    <img src={user.profilePicture} className="object-contain aspect-square" alt="Spaceship of user" />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex-grow py-4 px-2 break-all mr-8">
                  {user.name}
                  {user.id === authService.getUserId() ? (
                    <span className="px-2 py-1 bg-red-900 rounded ml-4 whitespace-nowrap">me</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="pr-4 py-4 min-w-[4rem] text-right">{user.experiencePoints} XP</div>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
