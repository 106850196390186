import { useState } from 'react';
import authService from '../services/auth.service';
import WalletName from '../models/WalletName';
import backendService from '../services/backend.service';
import { toast } from 'react-toastify';

interface Props {
  name: WalletName;
}

export default function CardanoCIP0030ConnectButton(props: Props) {
  const [cardanoStatus, setCardanoStatus] = useState('Click to connect wallet');

  const getWalletObject = (name: string) => {
    return (window as any).cardano[name];
  };

  const isAvailableInBrowser = (): boolean => {
    return (window as any).cardano && getWalletObject(props.name);
  };

  const toHex = (str: string): string => {
    let result = '';
    for (let i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return result;
  };

  const handleClick = async () => {
    if (isAvailableInBrowser()) {
      try {
        let wallet = await getWalletObject(props.name).enable();
        if (!wallet) {
          toast.error(`Connection to ${props.name} wallet failed`);
          return;
        }

        const usedAddressesOfWallet = await wallet.getUsedAddresses();
        // there is currently only one key active at once - TODO: Maybe not?
        const address = usedAddressesOfWallet[0];
        console.log('Using the following address of the user: ' + address);

        // get token from API to verify identity
        const response = await fetch(backendService.API_URL + '/api/connect/cardano/getToken');
        const responseObject = await response.json();

        // prove identity
        const message: string = responseObject.messageToSign;
        const signature = await wallet.signData(address, toHex(message));
        console.log('Signature: ' + signature);

        const verifyResponse = await fetch(backendService.API_URL + '/api/connect/cardano/verifyToken', {
          method: 'POST',
          headers: new Headers({ 'content-type': 'application/json' }),
          body: JSON.stringify({
            signedMessage: signature,
            walletConnector: props.name,
          }),
        });

        console.log('Login complete. Response was: ', verifyResponse);
        localStorage.setItem('cardanoWalletConnector', props.name);
        if (verifyResponse.ok) {
          const authTokens = await verifyResponse.json();
          authService.saveAuthToken(authTokens);
          toast.success(`Successfully connected ${props.name} wallet`);
        } else {
          toast.error('Error while connecting wallet, signature validation failed');
        }
      } catch (err) {
        let errormessage = err;
        if ('info' in err) {
          errormessage = err.info;
        }
        toast.error(`Cannot connect ${props.name} wallet. Error: ${errormessage}`);
        console.log('Cannot connect wallet. Error: ', err);
      }
    } else {
      toast.warn(`${props.name} wallet is not available in your browser. Please try another.`);
    }
  };

  // hack because ccvault is usually not there on initial DOM load. Maybe this
  // will be fixed in the future, than this hack can be removed
  if (!isAvailableInBrowser()) {
    setTimeout(() => {
      if (isAvailableInBrowser()) {
        setCardanoStatus('Click to connect wallet');
      }
    }, 1000);
  }

  return (
    <button
      onClick={handleClick}
      className={
        (isAvailableInBrowser() ? 'bg-gray-200 hover:bg-gray-400 hover:shadow-xl' : 'bg-gray-50') +
        ' rounded p-4 flex flex-row justify-between w-full transition duration-200'
      }
    >
      <img
        src={`/img/wallets/cardano-${props.name}.png`}
        alt={`Icon for Wallet ${props.name}`}
        className="h-12 flex-1 flex-grow-0"
      />
      <div className="mx-3 flex-1 flex-grow text-left">
        <p className="font-bold">Connect with {props.name} Cardano Wallet</p>
        {isAvailableInBrowser() ? (
          <p className="text-gray-800">{cardanoStatus}</p>
        ) : (
          <p className="text-red-700">Not available</p>
        )}
      </div>
    </button>
  );
}
