import { BetaAlien } from '../models/betaAlien';
import authService from './auth.service';
import backendService from './backend.service';

class AlienService {
  public async getBetaAlienOfUser(): Promise<BetaAlien> {
    return fetch(backendService.API_URL + '/api/alien', {
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.status === 204) {
        return Promise.resolve(null);
      }
      if (response.status === 418) {
        return Promise.reject('Mint not available anymore');
      }
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Error while getting beta alien!');
    });
  }

  public async retrySignBetaAlienCreationTx(id: string, rawTx: string) {
    console.log('Request sign of tx via web3 connector...');
    const walletName = localStorage.getItem('cardanoWalletConnector');
    const cardanoWallet = await (window as any).cardano[walletName].enable();
    const witness = await cardanoWallet.signTx(rawTx, true);
    console.log('Witness: ' + witness);

    const txSubmitResponse = await fetch(backendService.API_URL + '/api/alien/submitWitness', {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        userWitness: witness,
      }),
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    });
    if (!txSubmitResponse.ok) {
      return Promise.reject('Something went wrong when retry sign the alien tx :/');
    }
  }

  public async createBetaAlien(captchaToken: string): Promise<BetaAlien> {
    const response = await fetch(backendService.API_URL + '/api/alien', {
      method: 'PUT',
      body: JSON.stringify({
        captchaToken: captchaToken,
      }),
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Error while creating beta alien!');
    });

    if (response.mintRawTx != null) {
      // user has to sign spaceship mint tx
      console.log('Request sign of tx via web3 connector...');
      const walletName = localStorage.getItem('cardanoWalletConnector');
      const cardanoWallet = await (window as any).cardano[walletName].enable();
      const witness = await cardanoWallet.signTx(response.mintRawTx, true);
      console.log('Witness: ' + witness);

      const txSubmitResponse = await fetch(backendService.API_URL + '/api/alien/submitWitness', {
        method: 'POST',
        body: JSON.stringify({
          id: response.id,
          userWitness: witness,
        }),
        headers: {
          authorization: authService.getAuthToken(),
          'Content-Type': 'application/json',
        },
      });
      if (!txSubmitResponse.ok) {
        return Promise.reject('Something went wrong creating the beta alien :/');
      }
      return await txSubmitResponse.json();
    }

    return response;
  }

  public async getAllBetaAliens(): Promise<BetaAlien[]> {
    const response = await fetch(backendService.API_URL + '/api/admin/betaaliens', {
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Cannot load all beta aliens');
    });
    return response;
  }
}

export default new AlienService();
