import { useEffect, useState } from 'react';
import runningGamesService from '../../services/runningGames.service';
import { AdminGameState, RunningGame } from '../../models/runningGame';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faGamepad, faGlassCheers, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function ShowRunningGameInformation() {
  const [runningGames, setRunningGames] = useState<RunningGame[]>();
  let reloading = undefined;

  useEffect(() => {
    loadRunningGames();
    reloading = setInterval(() => loadRunningGames(), 10000);
  }, []);

  useEffect(
    () => () => {
      clearInterval(reloading);
    },
    []
  );

  const loadRunningGames = () => {
    runningGamesService.getRunningGames().then((r) => r.json().then((o) => setRunningGames(o)));
  };

  return (
    <div className="admin-warn-background">
      <div className="container mx-auto py-10 px-4 min-h-screen">
        <h1 className="text-4xl mb-4">
          <b>
            <Link to="/admin">ADMIN</Link>
          </b>{' '}
          / Games
        </h1>
        <table className="w-full divide-y divide-white text-white">
          <thead className="text-left font-bold uppercase tracking-wider">
            <tr>
              <th scope="col" className="py-3">
                State
              </th>
              <th scope="col" className="py-3">
                GameId
              </th>
              <th scope="col" className="py-3">
                Player1
              </th>
              <th scope="col" className="py-3">
                Player2
              </th>
            </tr>
          </thead>
          <tbody>
            {runningGames
              ? runningGames.map((runningGame) => {
                  return (
                    <tr>
                      <td title={runningGame.gameState} className="text-2xl">
                        {runningGame.gameState === AdminGameState.MATCHMAKING ? (
                          <FontAwesomeIcon icon={faGlassCheers} />
                        ) : null}
                        {runningGame.gameState === AdminGameState.PLAYING ? <FontAwesomeIcon icon={faGamepad} /> : null}
                        {runningGame.gameState === AdminGameState.FINISHED ? <FontAwesomeIcon icon={faFlag} /> : null}
                      </td>
                      <td>
                        {runningGame.gameId ? runningGame.gameId : '-'}{' '}
                        {runningGame.currentRound !== 0 ? '(Round ' + runningGame.currentRound + ')' : null}{' '}
                        {runningGame.gameState === AdminGameState.FINISHED ? (
                          <Link to={'/admin/game/' + runningGame.gameId}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </Link>
                        ) : null}
                      </td>
                      <td>{runningGame.player1 ? runningGame.player1 : '-'}</td>
                      <td>{runningGame.player2 ? runningGame.player2 : '-'}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}
