import { Spaceship } from '../models/spaceship';
import authService from './auth.service';
import backendService from './backend.service';

class SpaceshipService {
  getSpaceshipById(id) {
    return fetch(backendService.API_URL + '/api/spaceship/' + id);
  }

  getSpaceshipsOfCurrentUser() {
    if (!authService.isLoggedIn()) {
      return Promise.resolve();
    }
    return fetch(backendService.API_URL + '/api/user/' + authService.getUserId() + '/spaceships', {
      headers: new Headers({ authorization: authService.getAuthToken() }),
    });
  }

  deleteSpaceship(id: string) {
    return fetch(backendService.API_URL + '/api/spaceship/' + id, {
      method: 'DELETE',
      headers: new Headers({ authorization: authService.getAuthToken() }),
    });
  }

  public async getAllSpaceshipsForAdmin(): Promise<Spaceship[]> {
    const response = await fetch(backendService.API_URL + '/api/admin/spaceships', {
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Cannot load all spaceships');
    });
    return response;
  }
}
export default new SpaceshipService();
