import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import ConsoleScamWarning from './console-scam-warning';

export default function Footer() {
  return (
    <footer className="w-full bg-white px-4">
      <div className="container mx-auto py-8 flex flex-wrap justify-between">
        <div className="text-center text-gray-700">&copy; by Shipy.Space Team</div>
        <div className="flex flex-grow flex-1 text-4xl items-end w-auto">
          <ul className="flex justify-end flex-1 text-black">
            <li>
              <a
                href="https://twitter.com/ShipySpace"
                target="_blank"
                rel="noreferrer"
                className="mx-2 flex-1 items-center hover:text-gray-700 transition duration-300 ease-in-out"
              >
                <FontAwesomeIcon icon={faTwitter} title="Twitter Link" />
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/7uGsJdaNAn"
                target="_blank"
                rel="noreferrer"
                className="mx-2 flex-1 items-center hover:text-gray-700 transition duration-300 ease-in-out"
              >
                <FontAwesomeIcon icon={faDiscord} title="Discord Link" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ConsoleScamWarning />
    </footer>
  );
}
