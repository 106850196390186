import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { User } from '../../models/user';
import userService from '../../services/user.service';

export default function UsersAdminPage() {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    loadAllUsers();
  }, []);

  const loadAllUsers = () => {
    userService
      .getAllUsers()
      .then((users) => setUsers(users))
      .catch((err) => toast.error('Cannot fetch users. Error: ', err));
  };

  return (
    <div className="admin-warn-background">
      <div className="container mx-auto py-10 px-4 min-h-screen">
        <h1 className="text-4xl mb-4">
          <b>
            <Link to="/admin">ADMIN</Link>
          </b>{' '}
          / Users
        </h1>

        <div className="flex flex-col">
          {users
            ? users.map((user) => {
                return (
                  <Link
                    to={`/admin/user/${user.id}`}
                    key={user.id}
                    className="flex flex-row bg-orange-400 rounded py-3 px-2 mb-4"
                  >
                    <div className="flex flex-col gap-2">
                      <div>{user.id}</div>
                      <div className="text-xl font-bold">{user.name}</div>
                      <div>
                        XP: {user.experiencePoints} / Tokens: {user.tokenAmount}
                      </div>
                    </div>
                  </Link>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}
