import i18n from 'i18next';
import I18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(I18nBackend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
  });
export default i18n;
