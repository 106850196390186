export interface BetaAlien {
  id: string;
  user: string;
  name: string;
  status: BetaAlienStatus;
  ipfsHash: string;
  imgUrl?: string;
  cardanoscanUrl?: string;
  poolpmUrl?: string;

  mintTimestamp?: Date;
  mintTx?: string;
  mintTxId?: string;
  mintRawTx?: string;
}

export enum BetaAlienStatus {
  PENDING_USER_SIGNATURE = 'PENDING_USER_SIGNATURE',
  SUBMITTED = 'SUBMITTED',
  FINALIZED = 'FINALIZED',
  CANCELLED = 'CANCELLED',
}
