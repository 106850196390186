import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CardanoCIP0030ConnectButton from '../components/cardano.cip0030.connector';
import WalletName from '../models/WalletName';
import authService from '../services/auth.service';

export default function LoginPage() {
  const [canRenewJwt, setCanRenewJwt] = useState(authService.canRenewJwt());

  useEffect(() => {
    if (canRenewJwt) {
      authService
        .renewJwt()
        .then((success) => {
          if (success) {
            window.location.reload();
          } else {
            setCanRenewJwt(false);
          }
        })
        .catch((err) => {
          setCanRenewJwt(false);
        });
    }
  }, [canRenewJwt]);

  if (authService.isLoggedIn()) {
    return <Navigate to="/game/hangar" />;
  }

  return (
    <>
      {canRenewJwt ? (
        <div className="min-h-screen home-gradient text-center">
          <h1 className="text-4xl font-bold text-black pt-10">Logging in... just a moment...</h1>
        </div>
      ) : (
        <div className="min-h-screen home-gradient">
          <div className="mx-auto max-w-lg py-10">
            <h1 className="text-4xl font-bold text-white text-center">Login</h1>
          </div>

          <div className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 shadow-2xl rounded-lg text-black">
            <h2 className="text-2xl mb-2">Login or Register</h2>
            <p>
              Please choose a wallet provider to login. If you have not logged in before, a new account will be created
              automatically.
            </p>

            <section className="mt-4">
              <CardanoCIP0030ConnectButton name={WalletName.nami}></CardanoCIP0030ConnectButton>
            </section>

            <section className="mt-4">
              <CardanoCIP0030ConnectButton name={WalletName.eternl}></CardanoCIP0030ConnectButton>
            </section>
          </div>
        </div>
      )}
    </>
  );
}
