import { useEffect, useState } from 'react';

interface Props {
  initialSecondsLeft?: number;
}

export default function Countdown(props: Props) {
  const { initialSecondsLeft = 0 } = props;
  const [seconds, setSeconds] = useState(initialSecondsLeft);

  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
  });

  return <div>Seconds left: {seconds}</div>;
}
