import lottie from 'lottie-web';
import { useRef, useEffect } from 'react';
import rocketAnimation from '../../animations/rocket-animation.json';

export default function WaitForGameComponent() {
  const animationRef = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: animationRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: rocketAnimation,
    });
  }, []);

  return (
    <div className="text-center">
      Waiting for Game to start...
      <div ref={animationRef} className="mx-auto max-w-xl" />
    </div>
  );
}
