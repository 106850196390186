import { useTranslation } from 'react-i18next';

interface Props {
  badgeKey: string;
}

const COLORS = {
  admin: 'bg-amber-500',
  betatester: 'bg-lime-600',
};

const badgeToColor = (badgeKey: string): string => {
  const color = COLORS[badgeKey];
  return color ?? 'bg-green-500';
};

export default function ProfileBadge(props: Props) {
  const { t } = useTranslation();

  return (
    <div className={'p-5 shadow-lg rounded text-center px-10 w-full md:w-fit ' + badgeToColor(props.badgeKey)}>
      <div>Badge</div>
      <span className="text-2xl font-bold">{t('badges.' + props.badgeKey)}</span>
    </div>
  );
}
