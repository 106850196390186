import { faChrome, faFirefoxBrowser } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProfileBadge from '../components/profile-badge';
import { User } from '../models/user';
import authService from '../services/auth.service';
import emojiService from '../services/emoji.service';

export default function MyProfilePage() {
  const [userProfile, setUserProfile] = useState<User>();
  const [allEmojis, setAllEmojis] = useState<string[]>([]);
  const session = authService.getRefreshToken();

  const logout = () => {
    authService.logout();
  };

  useEffect(() => {
    authService.getUser().then((response) => {
      if (response.ok) {
        response.json().then((user) => setUserProfile(user));
      } else {
        toast.error('Cannot load your profile, please try again later...');
      }
    });
    emojiService.getAllExistingEmojis().then((emojis) => setAllEmojis(emojis));
  }, []);

  if (!authService.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  const getBrowserIcon = (browser: string) => {
    if (browser === 'Chrome') {
      return faChrome;
    }
    if (browser === 'Firefox') {
      return faFirefoxBrowser;
    }
    return faQuestionCircle;
  };

  return (
    <div className="container mx-auto pt-10 min-h-screen px-4 mb-10">
      <h1 className="text-4xl">Your Profile</h1>
      {userProfile ? (
        <div>
          <div className="flex mt-6 space-x-4">
            <FontAwesomeIcon icon={faUser} size="4x" />
            <div>
              <p className="text-2xl break-all font-bold">{userProfile.name}</p>
              <p className="my-2 break-all">
                ID: <b>{userProfile.id}</b>
              </p>
            </div>
          </div>

          <div className="my-5">
            <h2 className="text-2xl w-full">Wallet</h2>
            {userProfile.wallets.map((wallet) => {
              return (
                <a
                  className="flex space-x-4 my-2 bg-gray-500 hover:bg-slate-400 p-5 shadow-lg rounded md:ml-4 transition duration-300 ease-in-out"
                  href={wallet.walletUrl}
                  target="_blank"
                  rel="noreferrer"
                  key={wallet.type + wallet.address}
                >
                  <img src={'/img/chains/' + wallet.type + '-white.svg'} alt={wallet.type + ' logo'} className="h-12" />
                  <div>
                    <p className="text-2xl">{wallet.type} Wallet</p>
                    <p className="break-all">{wallet.address}</p>
                  </div>
                </a>
              );
            })}
          </div>
          <div className="my-5 flex flex-wrap md:space-x-4 space-y-4">
            <h2 className="text-2xl w-full">Achivements</h2>
            <div className="grid grid-cols-3 gap-4 w-full md:w-fit md:gap-0 md:flex md:flex-wrap md:space-x-4">
              {' '}
              <Link to="/tokens" className="bg-blue-500 p-5 shadow-lg rounded text-center px-10">
                <div>NBD Tokens</div>
                <span className="text-2xl font-bold">{userProfile.tokenAmount}</span>
              </Link>
              <div className="bg-gray-500 p-5 shadow-lg rounded text-center px-10">
                <div>XP</div>
                <span className="text-2xl font-bold">{userProfile.experiencePoints}</span>
              </div>
              <div className="bg-gray-500 p-5 shadow-lg rounded text-center px-10">
                <div>Level</div>
                <span className="text-2xl font-bold">1</span>
              </div>
            </div>

            {userProfile.badges?.map((badge) => {
              return <ProfileBadge badgeKey={badge} key={badge} />;
            })}
          </div>

          <div className="my-5 flex flex-wrap space-x-4 space-y-4">
            <h2 className="text-2xl w-full">Emojis</h2>
            {allEmojis.map((emoji) => {
              const userAlreadyOwnsTheEmoji = userProfile.emojis?.filter((e) => e === emoji).length > 0;
              const emojiCardClasses = 'p-5 shadow-lg rounded text-center text-2xl';
              if (userAlreadyOwnsTheEmoji) {
                return (
                  <div key={emoji} className={'bg-green-500 ' + emojiCardClasses}>
                    {emoji}
                  </div>
                );
              }
              return (
                <Link to={'emoji/' + emoji} key={emoji} className={'bg-gray-500 ' + emojiCardClasses}>
                  {emoji}
                </Link>
              );
            })}
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      <h2 className="text-2xl mt-10 mb-4">Current Session</h2>
      <div className="flex">
        <FontAwesomeIcon icon={getBrowserIcon(session.browser)} size="5x" className="px-4" />

        <div>
          {session.name}
          <p>
            Creation: <b>{format(new Date(session.creationTime), 'yyyy-MM-dd hh:mm')}</b>
          </p>
          <p>
            ID: <b>{session.publicId}</b>
          </p>
        </div>
      </div>

      <button onClick={logout} className="button--primary mt-10">
        Logout
      </button>
    </div>
  );
}
