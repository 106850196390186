import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { User } from '../models/user';
import userService from '../services/user.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import ProfileBadge from '../components/profile-badge';
import SpaceshipItem from '../components/spaceship-item';
import EmptyViewComponent from '../components/empty-view';
import authService from '../services/auth.service';

export default function UserProfilePage() {
  const { id } = useParams();
  const [profile, setProfile] = useState<User>();

  useEffect(() => {
    userService.getProfile(id).then((user) => setProfile(user));
  }, [id]);

  return (
    <div className="container mx-auto py-10 min-h-screen px-4">
      {profile ? (
        <>
          <h1 className="text-4xl mb-4 break-all">
            Profile {profile.name}
            {profile.id === authService.getUserId() ? (
              <span className="px-2 py-1 bg-red-900 rounded whitespace-nowrap block mt-2 md:mt-0 md:ml-4 md:inline-block text-center">
                me
              </span>
            ) : (
              <></>
            )}
          </h1>
          <div className="flex flex-col md:flex-row">
            <div className="m-auto px-12 hidden md:block">
              <FontAwesomeIcon icon={faUser} size="4x" />
            </div>

            <div className="w-full">
              <div className="flex-grow my-5 grid grid-cols-2 text-center">
                <div className="bg-gray-500 rounded mr-4">
                  <div className="my-12">
                    <p>
                      <FontAwesomeIcon icon={faStar} /> XP
                    </p>
                    <p className="text-4xl font-bold">{profile.experiencePoints}</p>
                  </div>
                </div>
                <div className="bg-gray-500 rounded ml-4">
                  <div className="my-12">
                    <p>Level</p>
                    <p className="text-4xl font-bold">1</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="my-10">
            <h2 className="text-2xl w-full">Wallet</h2>
            {profile.wallets.map((wallet) => {
              return (
                <a
                  className="flex space-x-4 my-2 md:ml-4 bg-gray-500 hover:bg-slate-400 p-5 shadow-lg rounded transition duration-300 ease-in-out"
                  href={wallet.walletUrl}
                  target="_blank"
                  rel="noreferrer"
                  key={wallet.type + wallet.address}
                >
                  <img src={'/img/chains/' + wallet.type + '-white.svg'} alt={wallet.type + ' logo'} className="h-12" />
                  <div>
                    <p className="text-2xl">{wallet.type} Wallet</p>
                    <p className="break-all">{wallet.address}</p>
                  </div>
                </a>
              );
            })}
          </div>

          <div className="my-10 flex flex-wrap md:space-x-4 space-y-4">
            <h2 className="text-2xl w-full">Badges</h2>

            {profile.badges?.map((badge) => {
              return <ProfileBadge badgeKey={badge} key={badge} />;
            })}
          </div>

          <div className="my-10 flex flex-wrap">
            <h2 className="text-2xl w-full">Spaceships</h2>
            {profile.spaceships ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-16 md:gap-4">
                {profile.spaceships.map((spaceship) => {
                  return <SpaceshipItem spaceship={spaceship} key={spaceship.id} />;
                })}
                {profile.spaceships.length === 0 ? <EmptyViewComponent text="No spaceships yet..." /> : null}
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
}
