import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NotFoundPage() {
  return (
    <div className="py-10 min-h-screen px-4 error-gradient">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-5">
          <FontAwesomeIcon icon={faQuestionCircle} /> Page not found
        </h1>
        <p>These are not the droids you are looking for...</p>
      </div>
    </div>
  );
}
