import { Pilot } from '../models/pilot';
import { Spaceship } from '../models/spaceship';
import { SpaceshipRequestSubmit } from '../models/spaceshipRequest';
import authService from './auth.service';
import backendService from './backend.service';

class PilotService {
  getAvailablePilotsToAdd() {
    return fetch(backendService.API_URL + '/api/pilot/cardano/possiblePilots', {
      headers: new Headers({ authorization: authService.getAuthToken() }),
    });
  }

  getAvailableSpaceshipBlueprints() {
    return fetch(backendService.API_URL + '/api/blueprint', {
      headers: new Headers({ authorization: authService.getAuthToken() }),
    });
  }

  async createSpaceship(pilot: Pilot): Promise<string> {
    const response = await fetch(backendService.API_URL + '/api/pilot/cardano/addPilot', {
      method: 'POST',
      body: JSON.stringify(pilot),
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      return Promise.reject('Cannot create spaceship, please try again later...');
    }
    const responseData = await response.json();
    const status = await this.cardanoPilotPayment(responseData);

    console.log('Created spaceship: ', responseData, ' Status: ', status);
    return responseData.id;
  }

  async cardanoPilotPayment(spaceship: Spaceship): Promise<string> {
    if (spaceship.creationRawTx) {
      // user has to sign spaceship mint tx
      const walletName = localStorage.getItem('cardanoWalletConnector');
      const cardanoWallet = await (window as any).cardano[walletName].enable();
      const witness = await cardanoWallet.signTx(spaceship.creationRawTx, true);
      console.log('Witness: ' + witness);

      const txSubmitResponse = await fetch(backendService.API_URL + '/api/pilot/cardano/submitPilotCreationWitness', {
        method: 'POST',
        body: JSON.stringify({
          id: spaceship.id,
          userWitness: witness,
        } as SpaceshipRequestSubmit),
        headers: {
          authorization: authService.getAuthToken(),
          'Content-Type': 'application/json',
        },
      });
      if (!txSubmitResponse.ok) {
        return Promise.reject('Something went wrong creating the spaceship :/');
      }
      return Promise.resolve('OK');
    } else {
      if (!(await backendService.getStatus()).testEnvironmentFlag) {
        return Promise.reject('Spaceship does not have creationRawTx and is not ready for payment');
      }
    }
  }
}
export default new PilotService();
