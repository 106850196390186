import { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SpaceshipBlueprint } from '../../models/spaceshipBlueprint';
import authService from '../../services/auth.service';
import backendService from '../../services/backend.service';
import pilotService from '../../services/pilot.service';
import { Link } from 'react-router-dom';

export default function PreviewSpaceshipCreationPage() {
  const [pilotUrl, setPilotUrl] = useState<string>(
    'https://infura-ipfs.io/ipfs/QmYw8fbtbVVcx7kLmxYLRSGCbmN2DmUPEbZFwBNSNvH5p3'
  );
  const [blueprints, setBlueprints] = useState<SpaceshipBlueprint[]>([]);
  const [selectedBlueprint, setSelectedBlueprint] = useState<string>();
  const [resizeWidth, setResizeWidth] = useState<number>(300);
  const [yOffset, setYOffset] = useState<number>(200);
  const [flipVertically, setFlipVertically] = useState<boolean>(false);
  const [previewImg, setPreviewImg] = useState<string>();

  useEffect(() => {
    loadBlueprints();
  }, []);

  const loadBlueprints = () => {
    pilotService
      .getAvailableSpaceshipBlueprints()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          toast.error('Cannot load spaceship blueprints');
        }
      })
      .then((responseData) => {
        setBlueprints(responseData);
        setSelectedBlueprint(responseData[0].id);
      })
      .catch((err) => toast.error('Cannot fetch spaceship blueprints. Error: ', err));
  };

  const updatePreview = async (e: FormEvent) => {
    e.preventDefault();
    console.log('UpdatePreview() called');

    const request = {
      collectionParams: {
        resizeWidth: resizeWidth,
        yOffset: yOffset,
        flipVertically: flipVertically,
      },
      pilotNft: {
        imgUrl: pilotUrl,
      },
      blueprintId: selectedBlueprint,
    };

    const previewResponse = await fetch(backendService.API_URL + '/api/admin/createSpaceshipPicture', {
      method: 'post',
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    const rawPreview = await previewResponse.blob();
    setPreviewImg(URL.createObjectURL(rawPreview));
  };

  return (
    <div className="admin-warn-background">
      <div className="container mx-auto py-10 px-4 min-h-screen">
        <h1 className="text-4xl mb-4">
          <Link to="/admin">ADMIN</Link> / Preview Spaceship
        </h1>

        <div className="grid grid-cols-2 space-x-8">
          <div>
            <h2 className="text-2xl mb-8">Params</h2>
            <form onSubmit={updatePreview} className="mb-4 space-y-4">
              <label htmlFor="blueprintId" className="pt-4 block">
                Spaceship Blueprint
              </label>
              <select
                className="mr-4 py-4 text-black px-4 w-full rounded border-r-8 border-r-transparent"
                name="blueprintId"
                onChange={(e) => setSelectedBlueprint(e.target.value)}
              >
                {blueprints.map((blueprint) => {
                  return (
                    <option key={blueprint.id} value={blueprint.id}>
                      {blueprint.name}
                    </option>
                  );
                })}
              </select>

              <label htmlFor="pilotUrl" className="pt-4 block">
                Pilot URL:
              </label>
              <input
                className="mr-4 py-4 text-black rounded px-4 w-full"
                value={pilotUrl}
                onChange={(e) => setPilotUrl(e.target.value)}
                name="pilotUrl"
                id="pilotUrl"
              />

              <label htmlFor="resizeWidth" className="pt-4 block">
                Resize Width (Scale of the image):
              </label>
              <input
                type="number"
                className="mr-4 py-4 text-black rounded px-4 w-full"
                value={resizeWidth}
                onChange={(e) => setResizeWidth(+e.target.value)}
                name="resizeWidth"
                id="resizeWidth"
              />

              <label htmlFor="yOffset" className="pt-4 block">
                Y Offset (Move image down):
              </label>
              <input
                type="number"
                className="mr-4 py-4 text-black rounded px-4 w-full"
                value={yOffset}
                onChange={(e) => setYOffset(+e.target.value)}
                name="yOffset"
                id="yOffset"
              />

              <label htmlFor="flipVertically" className="pt-4 block">
                Flip Pilot image vertically:
              </label>
              <input
                type="checkbox"
                className="w-8 h-8"
                value={yOffset}
                onChange={(e) => setFlipVertically(e.target.checked)}
                name="flipVertically"
                id="flipVertically"
              />

              <div className="pt-8">
                <button type="submit" className="button--primary block w-full md:w-fit">
                  Preview
                </button>
              </div>
            </form>
          </div>

          <div>
            <div className="text-2xl">Result</div>
            {previewImg ? <img src={previewImg} alt="preview" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
