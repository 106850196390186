import HealthChangeComponent from './health-change';

interface Props {
  currentHealthPoints: number;
  maxHealth: number;
  currentDelta?: number;
}

export default function HealthBarComponent(props: Props) {
  const fillerStyle = (percentage: number, color: string) => {
    return {
      width: `${percentage}%`,
      backgroundColor: color,
    };
  };

  let percentage = 0;
  let color = 'green';
  if (props.currentHealthPoints > 0) {
    percentage = (props.currentHealthPoints / props.maxHealth) * 100;
  }
  if (props.currentHealthPoints < 40) {
    color = '#e74c3c';
  }

  return (
    <div className="mt-2">
      Health: {props.currentDelta !== undefined ? <HealthChangeComponent healthDelta={props.currentDelta} /> : null}
      <div className="bg-gray-400 mt-2">
        <div className="bg-green-700 h-3 long--transition" style={fillerStyle(percentage, color)}></div>
      </div>
      <p className="text-right">
        {props.currentHealthPoints} / {props.maxHealth}
      </p>
    </div>
  );
}
