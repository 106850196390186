import { useTranslation } from 'react-i18next';

export default function FaqPage() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto py-10 px-4 min-h-screen">
      <h1 className="text-4xl mb-8">{t('faq.header')}</h1>
      <p className="mb-8">{t('faq.subtitle')}</p>
      <div>
        <h2 className="text-3xl mb-2">{t('faq.q1')}</h2>
        <p className="mb-8">{t('faq.a1')}</p>
        <h2 className="text-3xl mb-2">{t('faq.q2')}</h2>
        <p className="mb-8">{t('faq.a2')}</p>
        <h2 className="text-3xl mb-2">{t('faq.q5')}</h2>
        <p className="mb-8">{t('faq.a5')}</p>
        <h2 className="text-3xl mb-2">{t('faq.q6')}</h2>
        <p className="mb-8">{t('faq.a6')}</p>
        <h2 className="text-3xl mb-2">{t('faq.q3')}</h2>
        <p className="mb-8">{t('faq.a3')}</p>
        <h2 className="text-3xl mb-2">{t('faq.q4')}</h2>
        <p className="mb-8">
          {t('faq.a4partial')}{' '}
          <a className="underline" href="https://app.tosidrop.io/cardano/claim" target="_blank" rel="noreferrer">
            TosiDrop
          </a>{' '}
          website.
        </p>
        <h2 className="text-3xl mb-2">{t('faq.q7')}</h2>
        <p className="mb-8">{t('faq.a7')}</p>
      </div>
    </div>
  );
}
