import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { User } from '../../models/user';
import userService from '../../services/user.service';
import ProfileBadge from '../../components/profile-badge';
import SpaceshipItem from '../../components/spaceship-item';
import EmptyViewComponent from '../../components/empty-view';

export default function SingleUserAdminView() {
  const { id } = useParams();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    userService
      .getUserAdmin(id)
      .then((user) => setUser(user))
      .catch((err) => toast.error(err));
  }, [id]);

  return (
    <div className="admin-warn-background">
      <div className="container mx-auto py-10 px-4 min-h-screen">
        <h1 className="text-4xl mb-4">
          <b>
            <Link to="/admin">ADMIN</Link>
          </b>{' '}
          / <Link to="/admin/users">Users</Link> / {id}
        </h1>

        {user ? (
          <div>
            <h2 className="text-2xl mb-4">{user.name}</h2>
            <div className="flex flex-wrap gap-4 mb-4 mx-4">
              {user.adaHandles?.map((adaHandle) => {
                return (
                  <div key={adaHandle} className="bg-sky-600 p-2 rounded shadow-md">
                    ${adaHandle}
                  </div>
                );
              })}
            </div>
            <div>
              {user.wallets.map((wallet) => {
                return (
                  <a
                    className="flex space-x-4 my-2 bg-gray-500 hover:bg-slate-400 p-5 shadow-lg rounded md:ml-4 transition duration-300 ease-in-out"
                    href={wallet.walletUrl}
                    target="_blank"
                    rel="noreferrer"
                    key={wallet.type + wallet.address}
                  >
                    <img
                      src={'/img/chains/' + wallet.type + '-white.svg'}
                      alt={wallet.type + ' logo'}
                      className="h-12"
                    />
                    <div>
                      <p className="text-2xl">{wallet.type} Wallet</p>
                      <p className="break-all">{wallet.address}</p>
                    </div>
                  </a>
                );
              })}
            </div>
            <div className="my-10 flex flex-wrap md:space-x-4 space-y-4">
              <h2 className="text-2xl w-full">User Stats</h2>

              <div className={'p-5 shadow-lg rounded text-center px-10 w-full md:w-fit bg-gray-500'}>
                <div>XPs</div>
                <span className="text-2xl font-bold">{user.experiencePoints}</span>
              </div>

              <div className={'p-5 shadow-lg rounded text-center px-10 w-full md:w-fit bg-gray-500'}>
                <div>Tokens</div>
                <span className="text-2xl font-bold">{user.tokenAmount}</span>
              </div>

              {user.badges?.map((badge) => {
                return <ProfileBadge badgeKey={badge} key={badge} />;
              })}
            </div>
            <div className="my-10 flex flex-wrap md:space-x-4 space-y-4">
              <h2 className="text-2xl w-full">Emojis</h2>
              {user.emojis?.map((emoji) => {
                return <div key={emoji}>{emoji}</div>;
              })}
              {user.emojis?.length === 0 && <p>No emojis yet</p>}
            </div>
            <div className="my-10 flex flex-wrap">
              <h2 className="text-2xl w-full">Spaceships</h2>
              {user.spaceships ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-16 md:gap-4">
                  {user.spaceships.map((spaceship) => {
                    return <SpaceshipItem spaceship={spaceship} key={spaceship.id} />;
                  })}
                  {user.spaceships.length === 0 ? <EmptyViewComponent text="No spaceships yet..." /> : null}
                </div>
              ) : null}
            </div>

            <div className="my-10 flex flex-col gap-2">
              <h2 className="text-2xl w-full">Games ({user.games?.length})</h2>

              {user.games?.map((game) => {
                const isWinner = game.winner === user.id;
                return (
                  <Link to={`/admin/game/${game.id}`} key={game.id}>
                    <div className={'px-4 py-2 shadow-lg rounded w-full ' + (isWinner ? 'bg-green-500' : 'bg-red-500')}>
                      <span className="font-bold">{game.id}</span> @ {game.startDate?.toLocaleString()}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}
