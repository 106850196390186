import './roadmap.css';
import Goal from './goal';

// component based on https://www.youtube.com/watch?v=zNccqv0g6Q4
export default function Roadmap() {
  return (
    <div className="roadmap-container">
      <div className="roadmap">
        <ul>
          <Goal
            title="Game Design"
            description="Define the game mechanics and create simple, physical prototypes for initial play testing. Refine the mechanics and apply balancing where necessary."
            deadline="Q1 2022 ✅"
          />
          <Goal
            title="Website and Socials"
            description="Create the landing page and social media channels for the project. Start building a community."
            deadline="Q1 2022 ✅"
          />
          <Goal
            title="Token Mint"
            description="Create the 1.010.101.010 Nebula Dust (NBD) Cardano native tokens."
            deadline="Q2 2022 ✅"
          />
          <Goal
            title="Prototype"
            description="Create a first, fully functional prototype of the game that can be played online without blockchain integration."
            deadline="Q2 2022 ✅"
          />
          <Goal
            title="ISPO"
            description="Collaboration with a stake pool to distribute Nebula Dust to delegators every epoch."
            deadline="Q2 2022 ✅"
          />
          <Goal
            title="Private Alpha"
            description="Test the game with a small set of players to get initial feedback and find bugs at an early stage."
            deadline="Q3 2022 ✅"
          />
          <Goal
            title="Shipy Space Beta Aliens NFT Drop"
            description="Small NFT drop for people interested in participating in the closed beta phase. There are still some NFTs left to be minted!"
            deadline="Q4 2022 ✅"
          />
          <Goal
            title="Beta Testing"
            description="Closed Beta testing, open to Shipy Space Beta Alien NFT owners."
            deadline="Q2 2023 ✅"
          />
          <Goal
            title="Release 1.0"
            description="First full release of the game with all major features in place. The biggest milestone of the project!"
            deadline="2023 ✅"
          />
          <Goal
            title="Content Updates"
            description="We plan to add new content to the game after the first release to keep the game interesting for new as well as veteran players. A major part of this will also be the integration of more NFT projects that can be used in the game."
            deadline="2024 and beyond"
          />
        </ul>
      </div>
    </div>
  );
}
