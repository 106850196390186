import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Roadmap from '../components/roadmap/roadmap';

export default function HomePage() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="min-h-screen">
      <div className="home-gradient py-24">
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
            <h1 className="my-4 text-5xl font-bold leading-tight">Are you ready for your Space adventure?</h1>
            <p className="text-2xl leading-normal mb-8">
              Get your NFT into Space and fight other players in an epic battle! Outsmart your opponents, earn your
              place on the leaderboard and earn Nebula Dust tokens.
            </p>
            <Link
              to="/login"
              className="mx-auto lg:mx-0 shadow-lg py-4 px-8 rounded orange-gradient text-black hover:scale-110 smooth--transition"
            >
              Start now
            </Link>
          </div>
          <div className="w-full md:w-3/5">
            <img src="img/ufo.png" alt="Shipy Space ufo" className="app-logo mx-auto" />
          </div>
        </div>
      </div>

      <section className="py-24">
        <div className="container mx-auto m-8 px-4 md:w-3/5">
          <iframe
            src="https://www.youtube-nocookie.com/embed/8QXecWcIeMg?si=LWr4ldjpv4hAGGQb"
            title="Gameplay Video"
            className="w-full mb-8 aspect-video border-none outline-none rounded-lg"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <h2 className="w-full text-4xl text-center">What is Shipy.Space?</h2>
          <p className="text-center text-xl my-4">
            There are quite a few NFT projects that have been abandoned or were never meant to have any sort of utility
            in the first place. Our mission is to change that!
          </p>
          <p className="text-center text-xl my-4">
            Shipy Space is a round based online game where players fight each other to gain fame and earn materials to
            craft new items for the game. Materials and in game items will be tradeable on the Blockchain. We also plan
            to have some offline or idle farming features in the game, for those players who don't have as much time to
            actively play but still want a slice of the play to earn rewards.
          </p>
          <p className="text-center text-xl my-4">
            Our most important design goals are to make the game fun and to keep it fair. There will be no pay to win
            aspects that influence the gameplay. But of course there will be visual perks to flex on your enemies!
          </p>
        </div>
      </section>

      <section id="ispo" className="py-24 bg-slate-400 text-black">
        <div className="container mx-auto m-8 px-4 md:w-3/5">
          <h2 className="w-full text-4xl text-center">Our free ISPO is live! </h2>
          <p className="text-center text-xl my-4">
            By participating you support us building the game. Participating is simple: Delegate your wallet to the
            SOLPI stake pool.
          </p>
          <div className="my-4 flex justify-center">
            <a
              href="https://adapools.org/pool/827900818541013d518ee6b2af343a973c8d836884fb000742307400"
              target="_blank"
              rel="noreferrer"
              className="shadow-lg py-4 px-8 rounded orange-gradient text-black hover:scale-110 smooth--transition"
            >
              Stake now
            </a>
          </div>

          <p className="text-center text-xl my-4">
            Every epoch you will get <b>1 Nebula Dust token</b> for each 10 ADA staked. Wait for at least 1 epoch, then
            get your rewards on TosiDrop. You don't have to withdraw your rewards every epoch. The rewards stack over
            time so you can get them whenever you want (rewards will expire after 1 year).
          </p>

          <div className="my-4 flex justify-center">
            <a
              href="https://app.tosidrop.io/"
              target="_blank"
              rel="noreferrer"
              className="shadow-lg py-4 px-8 rounded orange-gradient text-black hover:scale-110 smooth--transition"
            >
              Get your rewards
            </a>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="container mx-auto m-8 px-4 md:w-3/5">
          <h2 className="w-full text-4xl text-center">Roadmap</h2>
          <p className="text-center text-xl my-4">(Subject to change)</p>
          <div className="grid place-items-center pt-12">
            <Roadmap />
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="container mx-auto m-8 px-4 md:w-3/5">
          <h2 className="w-full text-4xl text-center">Any Questions?</h2>
          <p className="text-center text-xl my-4">Feel free to contact us on discord or check out or FAQ below:</p>
          <div className="my-4 flex justify-center">
            <Link
              to="/faq"
              className="shadow-lg py-4 px-8 rounded orange-gradient text-black hover:scale-110 smooth--transition"
            >
              FAQ Page
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
