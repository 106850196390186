import { AdminStats } from '../models/adminStats';
import { ServerStatus } from '../models/status';
import authService from './auth.service';

class BackendService {
  PROTOCOL = process.env.REACT_APP_BACKEND_PROTOCOL ?? 'http';
  HOSTNAME = process.env.REACT_APP_BACKEND_HOSTNAME ?? 'localhost:5000';
  API_URL = process.env.NODE_ENV !== 'production' ? `${this.PROTOCOL}://${this.HOSTNAME}` : '';

  private serverStatusData: ServerStatus = undefined;

  public getBackendHost(): string {
    return process.env.NODE_ENV !== 'production' ? this.HOSTNAME : window.location.host;
  }

  public async getStatus(): Promise<ServerStatus> {
    if (this.serverStatusData) {
      return this.serverStatusData;
    }
    const response = await fetch(this.API_URL + '/api/status');
    if (response.ok) {
      const statusData = await response.json();
      this.serverStatusData = statusData;
      return statusData;
    }
  }

  public async getAdminStats(): Promise<AdminStats> {
    const response = await fetch(this.API_URL + '/api/admin/stats', {
      headers: {
        authorization: authService.getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Cannot load admin stats');
    });
    return response;
  }
}
export default new BackendService();
