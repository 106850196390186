interface Params {
  title: string;
  description: string;
  deadline: string;
}

export default function Goal(params: Params) {
  const { title, description, deadline } = params;
  return (
    <li>
      <div className="roadmap-content">
        <h3 className="deadline">{deadline}</h3>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </li>
  );
}
