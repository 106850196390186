import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoginPage from './routes/login';
import NotFoundPage from './errorpages/not-found';
import LeaderboardPage from './routes/leaderboard';
import HomePage from './routes/home';
import HangarPage from './routes/hangar';
import GamePage from './routes/game';
import MyProfilePage from './routes/myProfile';
import CreateSpaceshipPage from './routes/createSpaceship';
import SpaceshipDetailViewPage from './routes/spaceshipDetailView';
import AboutPage from './routes/about';
import SupportedProjectsPage from './routes/supported-projects';
import TokensPage from './routes/tokens';
import EmojiPage from './routes/emoji';
import PreviewSpaceshipCreationPage from './routes/admin/previewSpaceshipCreation';
import GodotGameView from './routes/game2';
import AdminMainPage from './routes/admin/main';
import BetaAlienPage from './routes/beta-alien/beta-alien-page';
import FaqPage from './routes/faq';
import ShowRunningGameInformation from './routes/admin/runningGames';
import BetaAliensAdminPage from './routes/admin/betaAliens';
import UsersAdminPage from './routes/admin/users';
import SingleGameAdminView from './routes/admin/singleGame';
import UserProfilePage from './routes/profile';
import SpaceshipsAdminPage from './routes/admin/spaceships';
import SingleUserAdminView from './routes/admin/singleUser';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/myprofile" element={<MyProfilePage />} />
        <Route path="/myprofile/emoji/:emoji" element={<EmojiPage />} />
        <Route path="/profile/:id" element={<UserProfilePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/supported-projects" element={<SupportedProjectsPage />} />
        <Route path="/tokens" element={<TokensPage />} />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/beta-alien" element={<BetaAlienPage />} />
        <Route path="/createSpaceship" element={<CreateSpaceshipPage />} />
        <Route path="/spaceship/:id" element={<SpaceshipDetailViewPage />} />
        <Route path="/game/hangar" element={<HangarPage />} />
        <Route path="/legacyGame" element={<GamePage />} />
        <Route path="/game" element={<GodotGameView />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/admin" element={<AdminMainPage />} />
        <Route path="/admin/previewSpaceship" element={<PreviewSpaceshipCreationPage />} />
        <Route path="/admin/games" element={<ShowRunningGameInformation />} />
        <Route path="/admin/game/:id" element={<SingleGameAdminView />} />
        <Route path="/admin/users" element={<UsersAdminPage />} />
        <Route path="/admin/user/:id" element={<SingleUserAdminView />} />
        <Route path="/admin/betaaliens" element={<BetaAliensAdminPage />} />
        <Route path="/admin/spaceships" element={<SpaceshipsAdminPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
