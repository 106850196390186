import Lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { BetaAlien, BetaAlienStatus } from '../../models/betaAlien';
import betaAlienService from '../../services/alien.service';
import celebrationAnimation from '../../animations/celebration.json';

interface Props {
  alien: BetaAlien;
  reload: () => void;
}

export default function BetaAlienItem(props: Props) {
  const animation1Ref = useRef(null);
  const animation2Ref = useRef(null);

  useEffect(() => {
    if (
      props.alien.status !== BetaAlienStatus.PENDING_USER_SIGNATURE &&
      props.alien.status !== BetaAlienStatus.CANCELLED
    ) {
      Lottie.loadAnimation({
        container: animation1Ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: celebrationAnimation,
      });
      Lottie.loadAnimation({
        container: animation2Ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: celebrationAnimation,
      });
    }
  });

  const retrySign = () => {
    betaAlienService
      .retrySignBetaAlienCreationTx(props.alien.id, props.alien.mintRawTx)
      .then(() => {
        toast.success('Successfully created beta alien');
        props.reload();
      })
      .catch((err) => {
        toast.error('Error while signing transaction: ' + JSON.stringify(err));
      });
  };

  return (
    <>
      {props.alien.status !== BetaAlienStatus.PENDING_USER_SIGNATURE &&
        props.alien.status !== BetaAlienStatus.CANCELLED && (
          <div>
            <h2 className="text-xl mb-12">Congratulation, you got yourself one of the Beta Alien NFTs!</h2>
            <div className="flex flex-row w-full">
              <div ref={animation1Ref} className="max-w-xl" />
              <div className="flex-grow">
                <img src={props.alien.imgUrl} alt="Beta Alien" className="max-w-md mx-auto" />
              </div>
              <div ref={animation2Ref} className="max-w-xl" />
            </div>
            <div className="text-center">
              <h2 className="text-4xl mt-2">{props.alien.name}</h2>
              <p>Shipy Space Beta Alien Collection</p>
            </div>
            {props.alien.mintTxId && (
              <div className="mt-12 text-center gap-4">
                <div className="mb-12">
                  <a href={props.alien.cardanoscanUrl} target="_blank" rel="noreferrer" className="button--primary">
                    View Mint Transaction on cardanoscan.io
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      {props.alien.status === BetaAlienStatus.PENDING_USER_SIGNATURE && (
        <div className="mt-8 px-4 py-4 rounded bg-red-600">
          <p className="mb-4">Beta alien transation is not yet signed - please retry:</p>
          <button
            onClick={retrySign}
            className="shadow-lg py-4 px-8 rounded bg-gray-50 text-black hover:bg-gray-200 smooth--transition text-center"
          >
            Re-Sign Minting Transaction
          </button>
        </div>
      )}
      {props.alien.status === BetaAlienStatus.CANCELLED && (
        <div className="mt-8 px-4 py-4 rounded bg-red-600">
          <p className="mb-4">Your alien creation was declined - please contact devs for help</p>
        </div>
      )}
    </>
  );
}
