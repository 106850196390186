import { Link } from 'react-router-dom';

export default function AboutPage() {
  return (
    <div className="min-h-screen">
      <section className="container mx-auto py-10 px-4">
        <h1 className="text-4xl mb-4">Our Collections</h1>
        <div className="flex flex-col md:flex-row md:space-x-4 mb-24 md:mb-2">
          <img src="/img/token/NBD_token_256.png" className="w-32 h-32 mx-auto md:mx-0" alt="NBD Token Logo" />
          <div className="truncate">
            <h2 className="text-xl mt-5">Nebula Dust Token ($NBD)</h2>
            <p>Our utility token for the game</p>
            <p className="truncate">
              Policy ID: <b>2f6df15b42f2fe88d057e379853b1bba3b1b35df4795e9a32b736f07</b>
            </p>
            <div className="flex space-x-4">
              <a
                href="https://pool.pm/asset1730233p6lq2eg6qcdsx4awczmyur9hj77exw30"
                className="button--link w-full md:w-fit text-center"
                target="_blank"
                rel="noreferrer"
              >
                Pool.pm
              </a>
              <a
                href="https://cardanoscan.io/token/f45ea8c43af8159468186c0d5ebb02d93832de5e"
                className="button--link w-full md:w-fit text-center"
                target="_blank"
                rel="noreferrer"
              >
                Cardanoscan.io
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row mt-5 md:space-x-4 mb-24 md:mb-2">
          <img src="/img/ufo.png" className="w-32 mx-auto md:mx-0" alt="NBD Token Logo" />
          <div>
            <h2 className="text-xl mt-5">Spaceships</h2>
            <p>Collection of all our Spaceships</p>
            <p>
              Policy ID: <b>4449db78c4bddfc7db279ed26fb3bfd5a52c039def3149ce30d822f8</b>
            </p>
            <div className="flex space-x-4">
              <a
                href="https://pool.pm/policy/4449db78c4bddfc7db279ed26fb3bfd5a52c039def3149ce30d822f8"
                className="button--link w-full md:w-fit text-center"
                target="_blank"
                rel="noreferrer"
              >
                Pool.pm
              </a>
              <a
                href="https://cardanoscan.io/tokenPolicy/4449db78c4bddfc7db279ed26fb3bfd5a52c039def3149ce30d822f8"
                className="button--link w-full md:w-fit text-center"
                target="_blank"
                rel="noreferrer"
              >
                Cardanoscan.io
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row mt-5 md:space-x-4 mb-8 md:mb-2">
          <img src="/img/alien-1x1_400.png" className="w-32 h-32 mx-auto md:mx-0" alt="NBD Token Logo" />
          <div className="truncate">
            <h2 className="text-xl mt-2">Beta Aliens</h2>
            <p>Special NFT Collection for our early players.</p>
            <p>
              Policy ID: <b>2fb5d856fe09da9930a5bd3015ae3ad64e02803985bd3e9027a2f815</b>
            </p>
            <div className="flex space-x-4">
              <a
                href="https://www.jpg.store/collection/2fb5d856fe09da9930a5bd3015ae3ad64e02803985bd3e9027a2f815"
                className="button--link w-full md:w-fit text-center"
                target="_blank"
                rel="noreferrer"
              >
                jpeg.store
              </a>
              <a
                href="https://cardanoscan.io/tokenPolicy/2fb5d856fe09da9930a5bd3015ae3ad64e02803985bd3e9027a2f815"
                className="button--link w-full md:w-fit text-center"
                target="_blank"
                rel="noreferrer"
              >
                Cardanoscan.io
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 home-gradient">
        <div className="container mx-auto m-8 px-4 md:w-3/5">
          <h2 className="w-full text-4xl text-center">What is Shipy.Space?</h2>
          <p className="text-center text-xl my-4">
            Shipy Space is a round based online game where players fight each other to gain fame and earn materials to
            craft new items for the game. Materials and in game items will be tradeable on the Blockchain. We also plan
            to have some offline or idle farming features in the game, for those players who don't have as much time to
            actively play but still want a slice of the play to earn rewards.
          </p>
          <p className="text-center text-xl my-4">
            Our most important design goals are to make the game fun and to keep it fair. There will be no pay to win
            aspects that influence the gameplay. But of course there will be visual perks to flex on your enemies!
          </p>
          <div className="grid place-items-center pt-12">
            <a
              href="/Spacepaper.pdf"
              className="shadow-lg py-4 px-8 rounded orange-gradient text-black hover:scale-110 smooth--transition"
            >
              Download Spacepaper
            </a>
          </div>
        </div>
      </section>

      <section className="container mx-auto py-10 px-4">
        <h1 className="text-4xl mt-10 mb-4">Supported NFT Projects</h1>
        <p>Due to high quality standards, we currently only support some exclusive projects: </p>
        <div className="flex mt-4">
          <Link to="/supported-projects" className="button--primary w-full md:w-fit">
            See our supported projects here
          </Link>
        </div>
      </section>

      <section className="container mx-auto py-10 px-4">
        <h1 className="text-4xl mt-10 mb-4">Team</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 pb-24 space-y-8 md:space-y-0">
          <div className="mx-4">
            <img src="img/team/alien_eddex.png" alt="mascot of eddex" />
            <h3 className="text-xl font-bold">eddex</h3>
            <p>Head of Blockchain</p>
            <p>Software engineer with 9+ years of experience</p>
            <p>B.Sc. computer science</p>
          </div>
          <div className="mx-4">
            <img src="img/team/alien_motrot.png" alt="mascot of Motrot" />
            <h3 className="text-xl font-bold">Motrot</h3>
            <p>Head of Cybersecurity</p>
            <p>Software engineer with 9+ years of experience</p>
            <p>B.Sc. information and cyber security</p>
          </div>
          <div className="mx-4">
            <img src="img/team/alien_thejan.png" alt="mascot of TheJan" />
            <h3 className="text-xl font-bold">TheJan</h3>
            <p>Head of Development</p>
            <p>Software engineer with 9+ years of experience</p>
            <p>B.Sc. computer science</p>
          </div>
          <div></div>
        </div>
      </section>
    </div>
  );
}
