import { Pilot } from '../models/pilot';

interface Props {
  pilot: Pilot;
}

export default function PilotNft(props: Props) {
  const pilot = props.pilot;

  return (
    <div className="m-4 text-black lg:max-w-lg flex cursor-pointer">
      <div className="w-2/5 rounded shadow-2xl z-10 bg-white">
        <img src={pilot.imgUrl} className="object-cover h-full rounded" alt="NFT" />
      </div>
      <div className={(pilot.canBeAddedToGame ? 'bg-white' : 'bg-gray-400') + ' w-3/5 my-4 rounded-r'}>
        <div className="p-4 truncate">
          <h3 className="text-2xl font-bold truncate">{pilot.name}</h3>
          <p className="truncate">{pilot.collectionName}</p>
          {pilot.canBeAddedToGame ? <p>Available</p> : <p className="text-red-800">Not available</p>}
          <p className="truncate" title={pilot.assetId}>
            Asset ID: {pilot.assetId}
          </p>
        </div>
      </div>
    </div>
  );
}
