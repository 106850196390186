import { Outlet, useLocation } from 'react-router-dom';
import './styles/App.css';
import './styles/Game.css';
import './styles/font.css';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/footer';
import Header from './components/header';
import GeneralErrorBoundary from './errorpages/general-error-boundary';
import { ToastContainer } from 'react-toastify';

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Header />
      <GeneralErrorBoundary key={location.pathname}>
        <Outlet />
      </GeneralErrorBoundary>
      <Footer />
      <ToastContainer position="top-right" theme="colored" />
    </div>
  );
}

export default App;
