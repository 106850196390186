import { useState, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import Countdown from '../components/countdown';
import GameFinishedComponent from '../components/game/game-finished';
import WaitForActionComponent from '../components/game/wait-for-action';
import WaitForGameComponent from '../components/game/wait-for-game';
import MessageType from '../models/game.MessageType';
import { GameStates } from '../models/game.States';
import authService from '../services/auth.service';
import spaceshipService from '../services/spaceship.service';
import ActiveUtilityEffects from '../components/activeUtilityEffects';
import { Spaceship } from '../models/spaceship';
import { GameState } from '../models/game.model';
import HealthBarComponent from '../components/game/health-bar';
import { EffectShieldComponent } from '../components/effects/effect-shield';
import ActionBalloonComponent from '../components/game/action-balloon';
import Action from '../models/game.Actions';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import EmojiBalloon from '../components/game/emoji-balloon';
import backendService from '../services/backend.service';

let ws: WebSocket;

let opponentEmojiTimeout = undefined;
let selfEmojiTimeout = undefined;
const EMOJI_DISPLAY_TIME_IN_MS = 2000;

export default function GamePage() {
  const [gameState, setGameState] = useState<GameState>();
  const [status, setStatus] = useState(GameStates.WAITING_FOR_GAME);
  const [spaceshipSelf, setSpaceshipSelf] = useState<Spaceship>();
  const [spaceshipOpponent, setSpaceshipOpponent] = useState<Spaceship>();
  const [spaceshipVisibility, setSpaceshipVisibility] = useState({
    self: true,
    opponent: true,
  });
  const [emojiMessages, setEmojiMessages] = useState({
    self: undefined,
    opponent: undefined,
  });
  const [roundCountdownRunning, setRoundCountdownRunning] = useState(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    connect();
  }, []);

  useEffect(
    () => () => {
      console.log('Game was closed - disconnect Websocket');
      ws.close();
    },
    []
  );

  useEffect(() => {
    // handle warp effect visibility
    const visibility = spaceshipVisibility;
    if (
      gameState !== undefined &&
      gameState.typ === MessageType.RoundResult &&
      visibility.self === true &&
      visibility.opponent === true
    ) {
      if (gameState.value.currentRound.selfAction === Action.warpDefense) {
        visibility.self = false;
      }
      if (gameState.value.currentRound.opponentAction === Action.warpDefense) {
        visibility.opponent = false;
      }

      if (visibility.self === false || visibility.opponent === false) {
        // set state and reset it after a short time
        setSpaceshipVisibility(visibility);
        setTimeout(() => {
          setSpaceshipVisibility({ self: true, opponent: true });
        }, 2000);
      }
    }
  });

  const loadSpaceships = (data: GameState) => {
    console.log('Load spaceships... ', data);
    spaceshipService
      .getSpaceshipById(data.value.self.spaceshipId)
      .then((response) => {
        if (response.ok) {
          response.json().then((r) => setSpaceshipSelf(r));
        } else {
          toast.warn('Cannot load your spaceship...');
        }
      })
      .catch((err) => toast.warn('Cannot load your spaceship. Error: ' + err));
    spaceshipService
      .getSpaceshipById(data.value.opponent.spaceshipId)
      .then((response) => {
        if (response.ok) {
          response.json().then((r) => setSpaceshipOpponent(r));
        } else {
          toast.warn('Cannot load opponent spaceship...');
        }
      })
      .catch((err) => toast.warn('Cannot load opponent spaceship. Error: ' + err));
  };

  const connect = () => {
    if (ws) {
      console.log('Already active websocket - close it first');
      ws.close();
    }
    ws = new WebSocket('ws://' + backendService.getBackendHost() + '/api/game');

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.typ !== MessageType.EmojiMessage) {
        setGameState(data);
      }
      if (data.typ === MessageType.LoginResponse) {
        console.log(data.err ? data.err : 'Login successful, waiting for other player...');
      }
      if (data.typ === MessageType.GameStarted) {
        toast.success("Found another player, let's go!");
        setStatus(GameStates.SELECT_ACTION);
        setRoundCountdownRunning(true);
        loadSpaceships(data);
      }
      if (data.typ === MessageType.RoundResult) {
        console.log('Round result received');
        setStatus(GameStates.SELECT_ACTION);
        // restart countdown for next round
        setRoundCountdownRunning(false);
        setRoundCountdownRunning(true);
      }
      if (data.typ === MessageType.EmojiMessage) {
        console.log('Got an emoji from opponent: ' + data.value);
        setEmojiMessages({ self: emojiMessages.self, opponent: data.value });
        if (opponentEmojiTimeout) {
          clearTimeout(opponentEmojiTimeout);
        }
        setTimeout(() => {
          setEmojiMessages({ self: emojiMessages.self, opponent: undefined });
        }, EMOJI_DISPLAY_TIME_IN_MS);
      }
      console.log('message from server: ' + JSON.stringify(data, null, 2));
    };

    ws.onclose = (event) => {
      console.log('Disconnected from server');
      setTimeout(() => setStatus(GameStates.GAME_FINISHED), 5000);
      setRoundCountdownRunning(false);
    };

    ws.onopen = () => {
      setStatus(GameStates.WAITING_FOR_GAME);
      console.log('Connected, authenticating...');
      ws.send(
        JSON.stringify({
          typ: MessageType.Login,
          value: {
            token: authService.getAuthToken(),
            selectSpaceship: searchParams.get('ship'),
          },
        })
      );
    };
  };

  const selectAction = (id: string) => {
    console.log('send action ' + id);
    setStatus(GameStates.WAIT_FOR_ACTION);
    setRoundCountdownRunning(false);
    ws.send(
      JSON.stringify({
        typ: MessageType.SelectAction,
        value: id,
      })
    );
  };

  const sendEmoji = (emoji: string) => {
    console.log('send emoji ' + emoji);
    ws.send(
      JSON.stringify({
        typ: MessageType.EmojiMessage,
        value: emoji,
      })
    );
    setEmojiMessages({ self: emoji, opponent: emojiMessages.opponent });
    if (selfEmojiTimeout) {
      clearTimeout(selfEmojiTimeout);
    }
    setTimeout(() => {
      setEmojiMessages({ self: undefined, opponent: emojiMessages.opponent });
    }, EMOJI_DISPLAY_TIME_IN_MS);
  };

  if (!authService.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container mx-auto py-10 min-h-screen px-4">
      <div>
        {status !== GameStates.GAME_FINISHED && status !== GameStates.WAITING_FOR_GAME ? (
          <div className="flex flex-row mb-16 items-stretch">
            <div className="flex">
              <div className="max-w-xs">
                <EmojiBalloon emojiIcon={emojiMessages.self} />
                <h2 className="text-2xl text-center">You</h2>
                <p className="text-center">({gameState.value.self.playerId})</p>
                {spaceshipSelf ? (
                  <>
                    <img
                      src={spaceshipSelf.img}
                      alt="Own Spaceship"
                      className={'game--spaceship ' + (spaceshipVisibility.self ? 'opacity-100' : 'opacity-0')}
                    />
                    <p className="truncate">{spaceshipSelf.name}</p>
                    <HealthBarComponent
                      currentHealthPoints={gameState.value.self.healthPoints}
                      maxHealth={spaceshipSelf.health}
                      currentDelta={gameState.value.currentRound.deltaHealthSelf}
                    />
                    <div>
                      <ActiveUtilityEffects activeUtilityEffects={gameState.value.self.activeUtilityEffects} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="flex flex-grow">
              {gameState.value.currentRound.selfAction !== undefined ? (
                <>
                  <EffectShieldComponent player={gameState.value.self} />
                  <ActionBalloonComponent
                    actionSelf={gameState.value.currentRound.selfAction}
                    actionOpponent={gameState.value.currentRound.opponentAction}
                    round={gameState.value.currentRound.roundNumber}
                  />
                  <EffectShieldComponent player={gameState.value.opponent} />
                </>
              ) : null}
            </div>
            <div className="flex justify-end">
              <div className="max-w-xs">
                <EmojiBalloon emojiIcon={emojiMessages.opponent} />
                <h2 className="text-2xl text-center">Player</h2>
                <p className="text-center">{gameState.value.opponent.playerId}</p>
                {spaceshipOpponent ? (
                  <>
                    <img
                      src={spaceshipOpponent.img}
                      alt="Opponent Spaceship"
                      className={'game--spaceship ' + (spaceshipVisibility.opponent ? 'opacity-100' : 'opacity-0')}
                    />
                    <p className="truncate">{spaceshipOpponent.name}</p>
                    <HealthBarComponent
                      currentHealthPoints={gameState.value.opponent.healthPoints}
                      maxHealth={spaceshipOpponent.health}
                      currentDelta={gameState.value.currentRound.deltaHealthOpponent}
                    />
                    <div>
                      <ActiveUtilityEffects activeUtilityEffects={gameState.value.opponent.activeUtilityEffects} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {status === GameStates.SELECT_ACTION ? (
          <>
            {roundCountdownRunning ? <Countdown initialSecondsLeft={20} /> : null}
            <div className="flex flex-row w-full flex-wrap mb-8">
              {gameState.value.self.actions.map((action) => {
                const available = action.cooldown < 1;
                if (available) {
                  return (
                    <button
                      key={action.id}
                      onClick={() => selectAction(action.id)}
                      className="button--action bg-gray-50"
                      title={t('game.actionhelp.' + action.id)}
                    >
                      {t('game.actions.' + action.id)}
                    </button>
                  );
                } else {
                  return (
                    <button
                      className="button--action bg-gray-500"
                      title={t('game.actionhelp.' + action.id)}
                      key={action.id}
                      disabled
                    >
                      {t('game.actions.' + action.id)}
                      {action.cooldown > 0 ? (
                        <span>
                          <br /> (wait for {action.cooldown} rounds)
                        </span>
                      ) : null}
                    </button>
                  );
                }
              })}
              {
                // TODO: Remove before release, just for testing
              }
              <button key="invalid-action" onClick={() => selectAction('lol')} className="button--action bg-gray-50">
                ??? invalid action
              </button>
            </div>
            <div className="flex flex-row w-full flex-wrap">
              {gameState.value.self.emojis.map((emoji) => {
                return (
                  <button key={emoji} onClick={() => sendEmoji(emoji)} className="p-4 m-2 bg-gray-400 rounded">
                    {emoji}
                  </button>
                );
              })}
            </div>
          </>
        ) : null}
        {status === GameStates.WAITING_FOR_GAME ? <WaitForGameComponent /> : null}
        {status === GameStates.WAIT_FOR_ACTION ? <WaitForActionComponent /> : null}
        {status === GameStates.GAME_FINISHED ? (
          <GameFinishedComponent game={gameState} restartAction={connect} />
        ) : null}
      </div>
    </div>
  );
}
