import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmojiInfo } from '../models/emoji';
import emojiService from '../services/emoji.service';

export default function EmojiPage() {
  const { emoji } = useParams();
  const [emojiInfo, setEmojiInfo] = useState<EmojiInfo>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    emojiService
      .getEmojiInfo(emoji)
      .then((info) => setEmojiInfo(info))
      .catch((err) => toast.error(err));
  }, [emoji]);

  const buyEmoji = () => {
    emojiService
      .buyEmoji(emoji)
      .then((response) => {
        if (response.ok) {
          toast.success('You can now use ' + emoji);
          navigate('/myprofile');
        } else {
          toast.error('Cannot buy emoji now :/');
        }
      })
      .catch((err) => {
        toast.error('Cannot buy emoji now, error: ' + err);
      });
  };

  return (
    <div className="container mx-auto py-10 px-4 min-h-screen">
      <h1 className="text-4xl mb-8">{emoji}</h1>

      {emojiInfo ? (
        <div>
          {emojiInfo.alreadyPurchased ? (
            <p>You have already purchased this emoji</p>
          ) : (
            <button onClick={buyEmoji} className="button--primary mr-8">
              {t('emoji.buyButton', { amount: emojiInfo.priceNBD })}
            </button>
          )}

          <button onClick={() => window.history.back()} className="button--primary">
            Back
          </button>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
