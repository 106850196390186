import Lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import sadTearAnimation from '../../animations/sad-tear.json';

export default function BetaAlienNotAvailable() {
  const animationRef = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: animationRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: sadTearAnimation,
    });
  }, []);

  return (
    <div className="text-center text-xl font-bold mt-8 px-4 py-4 rounded bg-red-600">
      <p className="mb-8">Sorry, the mint is not available anymore</p>
      <div ref={animationRef} className="mx-auto max-w-xl" />
    </div>
  );
}
