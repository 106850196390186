import lottie from 'lottie-web';
import { useRef, useEffect } from 'react';
import winningCupAnimation from '../../animations/winning-cup.json';
import sadTearAnimation from '../../animations/sad-tear.json';
import { GameState } from '../../models/game.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

interface Props {
  game: GameState;
  restartAction: () => void;
}

export default function GameFinishedComponent(props: Props) {
  const animationRef = useRef(null);
  const game = props.game;
  const restartAction = props.restartAction;
  let isWinner = false;

  if (game.value.winner === game.value.self.playerId) {
    isWinner = true;
  }

  useEffect(() => {
    if (isWinner) {
      lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: winningCupAnimation,
        initialSegment: [0, 74],
      });
    } else {
      lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: sadTearAnimation,
      });
    }
  }, []);

  return (
    <div className="text-center">
      <p>Game finished.</p>
      {isWinner ? <div>You are the winner! Congraz</div> : <div>Bad luck, try again next time</div>}
      <div ref={animationRef} className="mx-auto max-w-xl" />
      {isWinner ? (
        <>
          {game.value.gainedTokens > 0 ? (
            <div className="my-4 py-4 rounded shadow bg-blue-500">
              <div className="mb-4">
                <FontAwesomeIcon icon={faCoins} className="text-5xl" />
              </div>
              <span className="text-2xl">+ {game.value.gainedTokens} NBD Tokens</span>
            </div>
          ) : null}
          <div className="my-4 py-4 rounded bg-green-500 text-2xl shadow">+ {game.value.gainedExperiencePoints} XP</div>
        </>
      ) : null}
      <button onClick={restartAction} className="button--primary">
        Start new game
      </button>
    </div>
  );
}
