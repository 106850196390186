export enum AdminGameState {
  MATCHMAKING = 'matchmaking',
  PLAYING = 'playing',
  FINISHED = 'finished',
}

export interface RunningGame {
  gameId?: string;
  gameState: AdminGameState;
  currentRound: number;
  player1?: string;
  player2?: string;
}
