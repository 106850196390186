import { Navigate } from 'react-router-dom';
import ReactGodot from '../components/godotgame';
import authService from '../services/auth.service';

export default function GodotGameView() {
  if (!authService.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container mx-auto py-10 min-h-screen px-4">
      <ReactGodot
        script="/wsgame/shipy-space.game.js"
        executable="/wsgame/shipy-space.game"
        pck="/wsgame/shipy-space.game.pck"
        width={100}
      />
    </div>
  );
}
