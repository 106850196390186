import { FormEvent, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TokenWithdrawTransaction from '../components/tokenWithdrawTransaction';
import { TokenBalance } from '../models/tokenBalance';
import { WithdrawRequest } from '../models/withdrawRequest';
import authService from '../services/auth.service';
import tokenService from '../services/token.service';

export default function TokensPage() {
  const [tokenBalance, setTokenBalance] = useState<TokenBalance>();
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    loadBalance();
  }, []);

  const loadBalance = () => {
    tokenService
      .getTokenBalance()
      .then((response) => {
        if (response.ok) {
          response.json().then((balance: TokenBalance) => {
            setTokenBalance(balance);
            setAmount(balance.ingameAmount);
          });
        } else {
          toast.error('Cannot load token balance :/');
        }
      })
      .catch((err) => {
        toast.error('Cannot load token balance. Error: ' + err);
      });
  };

  const handleTokenWithdraw = async (e: FormEvent) => {
    e.preventDefault();
    const walletName = localStorage.getItem('cardanoWalletConnector');
    const cardanoWallet = await (window as any).cardano[walletName].enable();
    const usedAddresses = await cardanoWallet.getUsedAddresses();
    if (amount > tokenBalance.ingameAmount) {
      toast.error(`Your NBD balance is too low to withdraw ${amount} NBD`);
      return;
    }
    if (usedAddresses.length < 1) {
      toast.error('Your wallet seems not to have any balance, cannot use it');
      return;
    }
    // just get the first used one for now
    const targetAddress = usedAddresses[0];
    const withdrawRequest = {
      amount: amount,
      targetAddress: targetAddress,
    } as WithdrawRequest;
    await tokenService.withdrawTokens(withdrawRequest);
    loadBalance();
  };

  if (!authService.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container mx-auto pt-10 min-h-screen px-4 mb-10">
      <h1 className="text-4xl mb-8">Your Nebula Dust Tokens ($NBD)</h1>
      {tokenBalance ? (
        <div>
          <div className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0 mb-16">
            <div className="bg-blue-500 py-10 px-5 rounded shadow-lg flex-grow">
              <h3 className="text-lg">Ingame Nebula Dust Tokens</h3>
              <span className="text-8xl">{tokenBalance.ingameAmount}</span>
            </div>
            <div className="bg-indigo-500 py-10 px-5 rounded shadow-lg flex-grow">
              <h3 className="text-lg">Onchain Nebula Dust Tokens</h3>
              <span className="text-8xl">{tokenBalance.onchainAmount}</span>
            </div>
          </div>

          <div className="mb-16">
            <h2 className="text-2xl mb-4">Withdraw Tokens</h2>
            <form onSubmit={handleTokenWithdraw} className="mb-4 space-y-4">
              <label htmlFor="amount" className="mr-4">
                Amount of Tokens:
              </label>
              <input
                type="number"
                className="mr-4 py-4 text-black rounded px-4 w-full md:w-fit number-input"
                value={amount}
                onChange={(e) => setAmount(+e.target.value)}
                name="amount"
                max={tokenBalance.ingameAmount}
                min={0}
                id="amount"
              />

              <button type="submit" className="button--primary w-full md:w-fit">
                Withdraw
              </button>
            </form>
            <div>
              This action will transfer your ingame NBD tokens into your Wallet. As this happens on chain, you will have
              to approve the transaction and also include 2 ADA to cover the minimum ADA in every transaction and also
              the transaction fees.
            </div>
          </div>

          <div className="mb-16">
            {tokenBalance.withdrawTransactions.length > 0 ? <h2 className="text-2xl mb-4">Transactions</h2> : null}
            {tokenBalance.withdrawTransactions.map((transaction) => (
              <TokenWithdrawTransaction
                key={'transaction-' + transaction.timestamp}
                transaction={transaction}
                reloadBalance={loadBalance}
              />
            ))}
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
