import { Badges, User } from '../models/user';
import backendService from './backend.service';

class AuthService {
  LOCAL_JWT_KEY = 'userToken';
  LOCAL_REFRESH_KEY = 'refreshToken';

  isLoggedIn(): boolean {
    const jwt = localStorage.getItem(this.LOCAL_JWT_KEY);
    if (jwt === null) {
      return false;
    }
    const jwtPayload = this.getAuthObject();
    if (jwtPayload === null || Date.now() >= jwtPayload.exp * 1000) {
      console.log('jwt expired...');
      localStorage.removeItem(this.LOCAL_JWT_KEY);
      return false;
    }
    return true;
  }

  isAdminUser(user: User) {
    return user.badges?.includes(Badges.ADMIN);
  }

  getUserId(): string {
    return this.getAuthObject()?.id;
  }

  getUser() {
    if (!this.isLoggedIn()) {
      return Promise.resolve();
    }
    const userId = this.getUserId();
    return fetch(backendService.API_URL + '/api/user/' + userId, {
      headers: new Headers({ authorization: this.getAuthToken() }),
    });
  }

  canRenewJwt(): boolean {
    return localStorage.getItem(this.LOCAL_REFRESH_KEY) !== null;
  }

  async renewJwt() {
    if (localStorage.getItem(this.LOCAL_REFRESH_KEY) === null) {
      return Promise.resolve();
    }
    return fetch(backendService.API_URL + '/api/user/refreshJwt', {
      method: 'POST',
      headers: new Headers({ 'content-type': 'text/plain' }),
      body: JSON.parse(localStorage.getItem(this.LOCAL_REFRESH_KEY)).secret,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          return Promise.reject();
        }
      })
      .then((jwt) => {
        localStorage.setItem(this.LOCAL_JWT_KEY, jwt);
        return true;
      });
  }

  getRefreshToken() {
    const token = localStorage.getItem(this.LOCAL_REFRESH_KEY);
    if (token) {
      return JSON.parse(token);
    }
    return token;
  }

  saveAuthToken(authTokens) {
    localStorage.setItem(this.LOCAL_JWT_KEY, authTokens.jwt);
    localStorage.setItem(this.LOCAL_REFRESH_KEY, JSON.stringify(authTokens.refreshToken));
    window.location.reload();
  }

  logout(): void {
    fetch(backendService.API_URL + '/api/user/invalidateRefreshToken', {
      method: 'POST',
      headers: new Headers({
        'content-type': 'text/plain',
        authorization: this.getAuthToken(),
      }),
      body: JSON.parse(localStorage.getItem(this.LOCAL_REFRESH_KEY)).publicId,
    });
    localStorage.removeItem(this.LOCAL_JWT_KEY);
    localStorage.removeItem(this.LOCAL_REFRESH_KEY);
    window.location.reload();
  }

  getAuthToken(): string {
    return localStorage.getItem(this.LOCAL_JWT_KEY);
  }

  getAuthObject(): any | null {
    if (this.getAuthToken() === null) {
      return null;
    }
    try {
      return JSON.parse(window.atob(this.getAuthToken().split('.')[1]));
    } catch (ex) {
      return null;
    }
  }
}

export default new AuthService();
