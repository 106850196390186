import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import authService from '../services/auth.service';
import { User } from '../models/user';
import { ServerStatus } from '../models/status';
import backendService from '../services/backend.service';
import { toast } from 'react-toastify';

export default function Header() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [status, setStatus] = useState<ServerStatus>();
  const { t } = useTranslation();

  useEffect(() => {
    authService
      .getUser()
      .then((res) => res.json())
      .then((user) => setCurrentUser(user));

    backendService
      .getStatus()
      .then((status) => setStatus(status))
      .catch((err) =>
        toast.error('Application is currently in maintenance, please try again later or see our Discord.')
      );
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  };

  return (
    <header className="w-full z-30 top-0 bg-gray-800 text-white drop-shadow-xl">
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <Link
            to="/"
            onClick={hideMenu}
            className="no-underline hover:no-underline text-2xl lg:text-4xl font-bold text-white"
          >
            <img src="/img/alien.png" alt="Logo" className="h-12 fill-current inline mr-4" />
            <span className="main-app-name">Shipy.Space</span>
          </Link>
          {status && status.testEnvironmentFlag && (
            <span className="ml-4 px-4 py-1 rounded bg-yellow-500">{status.testEnvironmentFlag}</span>
          )}
        </div>

        <div className="block lg:hidden pr-4">
          <button onClick={toggleMenu} className="flex items-center p-1 text-white button--bounce">
            <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <nav
          className={
            (menuVisible ? '' : 'hidden') +
            ' w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 bg-gray-800 lg:bg-transparent p-4 lg:p-0 z-20'
          }
        >
          <ul className="list-reset flex flex-col lg:flex-row lg:justify-end flex-1 items-center text-2xl gap-4 lg:text-base">
            {currentUser && authService.isAdminUser(currentUser) ? (
              <li className="font-bold text-red-600 hover:text-red-700">
                <Link to="/admin" onClick={hideMenu}>
                  {t('header.admin')}
                </Link>
              </li>
            ) : null}
            <li className="hover:text-gray-300">
              <Link to="/about" onClick={hideMenu}>
                {t('header.about')}
              </Link>
            </li>
            <li className="hover:text-gray-300">
              <Link to="/leaderboard" onClick={hideMenu}>
                {t('header.leaderboard')}
              </Link>
            </li>
            {authService.isLoggedIn() ? (
              <li className="hover:text-gray-300 font-bold">
                <Link to="/game/hangar" onClick={hideMenu}>
                  {t('header.myHangar')}
                </Link>
              </li>
            ) : null}
            <li className="mr-3 hover:text-gray-300">
              {authService.isLoggedIn() ? (
                <Link to="/myprofile" onClick={hideMenu}>
                  <span className="inline-block object-cover w-12 h-12 rounded-full bg-white mt-4 lg:mt-0">
                    <FontAwesomeIcon icon={faUser} className="block text-3xl m-auto mt-2" color="black" />
                  </span>
                </Link>
              ) : (
                <Link to="/login">{t('header.login')}</Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
