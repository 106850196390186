export enum TransactionStatus {
  PENDING_USER_SIGNATURE = 'PENDING_USER_SIGNATURE',
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
}

export interface TokenWithdrawal {
  timestamp: Date;
  userId: string;
  rawTx: string;
  amount: number;
  serverSignature: string;
  userSignature?: string;
  txId?: string;
  txUrl?: string;
  status: TransactionStatus;
  id?: string;
}

export interface WithdrawRequest {
  amount: number;
  targetAddress: string;
}

export interface WithdrawResponse {
  id: string;
  tx: string;
}

export interface WithdrawSubmit {
  id: string;
  userWitness: string;
}
