import { faBug, faRobot, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface Props {
  activeUtilityEffects: any;
}

export default function ActiveUtilityEffects(props: Props) {
  const AVAILABLE_EFFECTS = {
    nanomites: <FontAwesomeIcon icon={faBug} className="utility--effect--icon" fixedWidth />,
    shield: <FontAwesomeIcon icon={faShieldAlt} className="utility--effect--icon" fixedWidth />,
    repairBots: <FontAwesomeIcon icon={faRobot} className="utility--effect--icon" fixedWidth />,
  };

  const activeUtilityEffects = props.activeUtilityEffects;
  const { t } = useTranslation();

  return (
    <>
      {activeUtilityEffects.length > 0 ? <div>{t('game.effects.title')}:</div> : null}
      {activeUtilityEffects.map((activeUtilityEffect) => (
        <p key={activeUtilityEffect.id}>
          {AVAILABLE_EFFECTS[activeUtilityEffect.id]}
          {t('game.effects.' + activeUtilityEffect.id)}: {activeUtilityEffect.remainingRounds}
        </p>
      ))}
    </>
  );
}
