const enum Action {
  laserAttack = 'standardLaser',
  railgunAttack = 'railgun',
  nanomitesAttack = 'nanomites',
  atomicBombAttack = 'atomicBomb',
  shieldDefense = 'shield',
  magnetShieldDefense = 'magnetShield',
  warpDefense = 'warp',
  instantHealUtil = 'instantHeal',
  repairBotsUtil = 'repairBots',
  attackUpgradeUtil = 'attackUpgrade',
}
export default Action;
