interface Props {
  healthDelta: number;
}

export default function HealthChangeComponent(props: Props) {
  const isPositive = props.healthDelta > 0;
  const backgroundClass = isPositive ? 'bg-green-500' : 'bg-red-500';

  if (props.healthDelta === 0) {
    return <></>;
  }

  return (
    <span className={'ml-4 my-2 py-1 px-3 rounded ' + backgroundClass}>
      { isPositive ? '+' : '' }{props.healthDelta}
    </span>
  );
}
