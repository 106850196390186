import authService from './auth.service';
import backendService from './backend.service';

class RunningGamesService {
  getRunningGames() {
    return fetch(backendService.API_URL + '/api/admin/games/latest', {
      headers: new Headers({ authorization: authService.getAuthToken() }),
    });
  }

  getSingleGame(gameId: string) {
    return fetch(backendService.API_URL + '/api/admin/game/' + gameId, {
      headers: new Headers({ authorization: authService.getAuthToken() }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject('Error while getting singe game infos!');
    });
  }
}
export default new RunningGamesService();
