import { Chain } from './chain';
import { Pilot } from './pilot';

export interface Spaceship {
  id: string;
  name: string;
  type: Chain;
  isActive: boolean;
  status: SpaceshipState;
  currentOwnerId?: string;
  creationDate: string;
  experiencePoints: number;
  pilotNftId: string;
  ipfsHash: string;
  img: string;

  cardanoscanUrl: string;
  poolpmUrl: string;

  pilot: Pilot;
  actions: string[];
  creationRawTx?: string;

  // stats
  health: number;
  attackStrength: number;
  precision: number;
}

export enum SpaceshipState {
  CREATED = 'CREATED',
  BLOCKCHAIN_SUBMITTED = 'BLOCKCHAIN_SUBMITTED',
  PAYED = 'PAYED',
  BANNED = 'BANNED',
}
