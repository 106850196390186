import { useCountdown } from './useCountdownHook';

interface Props {
  targetDate: Date;
  disableCountdown: () => void;
}

export const CountdownTimer = (props: Props) => {
  const [days, hours, minutes, seconds] = useCountdown(props.targetDate);

  if (days + hours + minutes + seconds <= 0) {
    props.disableCountdown();
    return <span>Yey, please reload the page if it not automatically reloads.</span>;
  } else {
    return (
      <div className="countdown flex flex-wrap gap-4 text-center rounded justify-center bg-orange-500 py-16 text-lg">
        <div>
          <p className="font-bold">{days}</p>
          <p>Days</p>
        </div>
        <div className="flex justify-center items-center">
          <span>:</span>
        </div>
        <div>
          <p className="font-bold">{hours}</p>
          <p>Hours</p>
        </div>
        <div className="flex justify-center items-center">
          <span>:</span>
        </div>
        <div>
          <p className="font-bold">{minutes}</p>
          <p>Minutes</p>
        </div>
        <div className="flex justify-center items-center">
          <span>:</span>
        </div>
        <div>
          <p className="font-bold">{seconds}</p>
          <p>Seconds</p>
        </div>
      </div>
    );
  }
};
