import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmptyViewComponent from '../components/empty-view';
import PilotNft from '../components/pilotnft';
import WaitForSpaceshipCreationComponent from '../components/spaceship-creation-loading';
import { Pilot } from '../models/pilot';
import { SpaceshipBlueprint } from '../models/spaceshipBlueprint';
import authService from '../services/auth.service';
import pilotService from '../services/pilot.service';
import backendService from '../services/backend.service';
import HCaptcha from '@hcaptcha/react-hcaptcha';

export default function CreateSpaceshipPage() {
  const [availableNfts, setAvailableNfts] = useState<Pilot[]>();
  const [selectedNft, setSelectedNft] = useState<Pilot>();
  const [availableBlueprints, setAvailableBlueprints] = useState<SpaceshipBlueprint[]>();
  const [selectedBlueprint, setSelectedBlueprint] = useState<string>();
  const [isCreating, setIsCreating] = useState(false);
  const [hcaptchaKey, setHcaptchaKey] = useState<string>();
  const [captchaToken, setCaptchaToken] = useState<string>();
  const captcha = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadAvailableNfts();
    loadSpaceshipBlueprints();
    loadHcaptchaKey();
  }, []);

  const loadHcaptchaKey = async () => {
    const status = await backendService.getStatus();
    setHcaptchaKey(status.hcaptchaPublicSiteKey);
  };

  const loadAvailableNfts = () => {
    pilotService
      .getAvailablePilotsToAdd()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          toast.error('Cannot get your NFTs, please try again later');
        }
      })
      .then((response) => setAvailableNfts(response))
      .catch((err) => toast.error('Cannot fetch your NFTs! Error: ' + err));
  };

  const loadSpaceshipBlueprints = () => {
    pilotService
      .getAvailableSpaceshipBlueprints()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          toast.error('Cannot load spaceship blueprints');
        }
      })
      .then((responseData) => setAvailableBlueprints(responseData))
      .catch((err) => toast.error('Cannot fetch spaceship blueprints. Error: ', err));
  };

  const onSelectNft = (id) => {
    setSelectedNft(availableNfts.find((nft) => nft.assetId === id));
  };

  const createSpaceship = async () => {
    if (selectedNft === undefined) {
      toast.warn('Please select an NFT first');
      return;
    }

    if (!selectedNft.canBeAddedToGame) {
      toast.error('Please select an NFT that can be added. See our list of collections that we currently support.');
      return;
    }

    if (!captchaToken) {
      toast.error('Please solve the CAPTCHA first');
      return;
    }

    selectedNft.blueprintId = selectedBlueprint;
    selectedNft.captchaToken = captchaToken;

    setIsCreating(true);
    try {
      const spaceshipId = await pilotService.createSpaceship(selectedNft);
      toast.success('Spaceship created, have fun!');
      setIsCreating(false);
      navigate('/spaceship/' + spaceshipId);
    } catch (err) {
      setIsCreating(false);
      toast.error('Error while creating spaceship: ' + err);
    }
  };

  if (!authService.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container mx-auto pt-10 px-4 min-h-screen">
      <h1 className="text-4xl mb-4">Create a new Spaceship</h1>

      {!isCreating ? (
        <>
          <div className="flex flex-row justify-end">
            <button
              onClick={loadAvailableNfts}
              className="m-4 text-2xl text-black bg-gray-50 hover:bg-gray-200 smooth--transition text-center rounded-sm shadow-lg py-2 px-8"
            >
              <FontAwesomeIcon icon={faSync} title="Reload NFTs of wallet" />
            </button>
          </div>

          <h2 className="text-2xl mb-4">1. Choose your future spaceship</h2>
          {availableBlueprints ? (
            <fieldset className="mb-10 flex flex-col lg:flex-row flex-wrap gap-4">
              {availableBlueprints.map((blueprint) => {
                return (
                  <div
                    key={blueprint.id}
                    id={'blueprint-' + blueprint.id}
                    className={
                      (selectedBlueprint === blueprint.id ? 'selected-blueprint' : '') +
                      ' inline-block mx-4 bg-white shadow-md cursor-pointer rounded-md'
                    }
                    onClick={() => setSelectedBlueprint(blueprint.id)}
                  >
                    <img
                      src={'/img/blueprints/' + blueprint.id + '/preview.png'}
                      width="255px"
                      className="cursor-pointer px-4 mx-auto"
                      alt={'Blueprint ' + blueprint.name}
                    />
                    <span className="text-black cursor-pointer block text-center mb-2">{blueprint.name}</span>
                  </div>
                );
              })}
            </fieldset>
          ) : (
            <p>Loading...</p>
          )}

          <h2 className="text-2xl">2. Choose one of your NFTs to add as pilot</h2>
          <p className="my-10">
            Note: Only selected NFT collections can be used as pilots! If non of your NFTs can be used, feel free to
            contact your favorite NFT project and ask them to get their NFTs added to Shipy Space! (If you own a
            project, please contact us on discord)
          </p>
          <Link to="/supported-projects" className="button--primary my-8 block w-full md:w-fit">
            See Supported Collections
          </Link>
          {availableNfts ? (
            <>
              <div className="flex flex-col lg:flex-row mb-8 flex-wrap">
                {availableNfts
                  .filter((nft) => nft.canBeAddedToGame)
                  .map((nft) => {
                    return (
                      <div
                        key={nft.assetId}
                        onClick={() => onSelectNft(nft.assetId)}
                        className={
                          (selectedNft?.assetId === nft.assetId ? 'selected-spaceship' : '') + ' w-full lg:w-1/2'
                        }
                      >
                        <PilotNft pilot={nft} />
                      </div>
                    );
                  })}
                {availableNfts.filter((nft) => nft.canBeAddedToGame).length === 0 ? (
                  <EmptyViewComponent text="Your wallet does not contain any supported NFT... Go and buy one!" />
                ) : null}
              </div>

              <h2 className="text-2xl">3. Prove you are a human</h2>
              <p>Solve the CAPTCHA below:</p>
              <div className="mb-8">
                {hcaptchaKey && (
                  <HCaptcha
                    ref={captcha}
                    sitekey={hcaptchaKey}
                    onVerify={(token) => setCaptchaToken(token)}
                    onExpire={() => setCaptchaToken(undefined)}
                  />
                )}
              </div>

              {availableNfts.length > 0 ? (
                <button
                  onClick={createSpaceship}
                  className="button--primary my-8 w-full md:w-fit"
                  disabled={isCreating}
                >
                  {isCreating ? 'Working...' : 'Create Spaceship!'}
                </button>
              ) : null}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </>
      ) : (
        <WaitForSpaceshipCreationComponent />
      )}
    </div>
  );
}
