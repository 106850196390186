import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Spaceship } from '../../models/spaceship';
import spaceshipService from '../../services/spaceship.service';

export default function SpaceshipsAdminPage() {
  const [spaceships, setSpaceships] = useState<Spaceship[]>([]);

  useEffect(() => {
    loadSpaceships();
  }, []);

  const loadSpaceships = () => {
    spaceshipService
      .getAllSpaceshipsForAdmin()
      .then((spaceships) => setSpaceships(spaceships))
      .catch((err) => toast.error('Cannot fetch spaceships. Error: ', err));
  };

  return (
    <div className="admin-warn-background">
      <div className="container mx-auto py-10 px-4 min-h-screen">
        <h1 className="text-4xl mb-4">
          <b>
            <Link to="/admin">ADMIN</Link>
          </b>{' '}
          / Spaceships
        </h1>

        <div className="flex flex-col">
          {spaceships
            ? spaceships.map((spaceship) => {
                return (
                  <div key={spaceship.id} className="flex flex-row bg-orange-400 rounded py-3 px-2 mb-4">
                    <img src={spaceship.img} alt="Beta Alien Preview" className="w-32 h-32 mr-4" />
                    <div className="flex flex-col gap-2">
                      <div className="text-xl font-bold">{spaceship.name}</div>
                      <div>
                        {spaceship.status} @ {spaceship.creationDate}
                      </div>
                      <div>
                        Owner: <Link to={`/admin/user/${spaceship.currentOwnerId}`}>{spaceship.currentOwnerId}</Link>
                      </div>
                      <Link
                        to={`/spaceship/${spaceship.id}`}
                        className="shadow-lg py-2 px-2 rounded bg-gray-50 text-black hover:bg-gray-200 smooth--transition text-center"
                      >
                        Show spaceship
                      </Link>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}
